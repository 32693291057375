import { GroupNotificationDiscussion } from './groupnotification-discussion.model';

export interface Setting {
  id: number;
  profileVisibility: String;
  privateMessage: String;
  speciality1: String;
  speciality2: String;
  speciality3: String;
  speciality4: String;
  userId: number;
  userLogin: string;
  discussionGroup: GroupNotificationDiscussion[];
}

export class Setting implements Setting {
  constructor() {
    this.id = null;
    this.profileVisibility = null;
    this.privateMessage = null;
    this.speciality1 = null;
    this.speciality2 = null;
    this.speciality3 = null;
    this.speciality4 = null;
    this.discussionGroup = null;
  }
}
