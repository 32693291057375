import { Routes } from '@angular/router';

import {
  auditsRoute,
  configurationRoute,
  docsRoute,
  healthRoute,
  logsRoute,
  metricsRoute,
  // trackerRoute,
  userMgmtRoute
} from './';

import { UserRouteAccessService } from 'app/core';
import { dashboardRoute } from './dashboard/dashboard.route';
import { profileRoute } from './profile/profile.route';
import { messagesRoute } from './messages/messages.route';
import { postsRoute } from './posts/posts.route';
import { settingRoute } from './setting/setting.route';
import { groupsRoute } from './groups/groups.route';
import { searchRoute } from './search/search.component.route';
import { admindashboardRoute } from './admindashboard/admindashboard.route';
import { reviewSpecialitiesRoute } from './review-specialities/review-specialities.route';
import { endorsesRoute } from './endorses/endorses.route';
import { MdcprtRoute } from './Mdcprt/Mdcprt.route';
import { SpcltRoute } from './Spclt/Spclt.route';
import { searchResultDashboardRoute } from './search-result-dashboard/search-result-dashboard.route';

const ADMIN_ROUTES = [
  auditsRoute,
  configurationRoute,
  docsRoute,
  healthRoute,
  logsRoute,
  // trackerRoute,
  ...userMgmtRoute,
  metricsRoute,
  dashboardRoute,
  ...profileRoute,
  ...messagesRoute,
  ...postsRoute,
  settingRoute,
  ...groupsRoute,
  searchRoute,
  admindashboardRoute,
  reviewSpecialitiesRoute,
  endorsesRoute,
  MdcprtRoute,
  SpcltRoute,
  searchResultDashboardRoute
];

export const adminState: Routes = [
  {
    path: '',
    data: {
      authorities: ['ROLE_ADMIN', 'ROLE_USER']
    },
    canActivate: [UserRouteAccessService],
    children: ADMIN_ROUTES
  }
];
