export interface ISearchInfo {
  location?: string;
  number?: string;
  street?: string;
  locality?: string;
  city?: string;
  state?: string;
  postCode?: string;
  country?: string;
  
  usualAvailability?: number;
  specialities?: number[];
  lat?: number;
  lng?: number;
  distance?: number;
  
  dayOfWeek?: string;
  mon?: boolean;
  tue?: boolean;
  wed?: boolean;
  thu?: boolean;
  fri?: boolean;
  sat?: boolean;
  sun?: boolean;
  
  timeOfDay?: string;
  after?: string;
  before?: string;
  
  userType?: string;
  searchstring?: string;
}

export class SearchInfo implements ISearchInfo {
  constructor(
    public location?: string,
    public number?: string,
    public street?: string,
    public locality?: string,
    public city?: string,
    public state?: string,
    public postCode?: string,
    public country?: string,
    
    public usualAvailability?: number,
    public specialities?: number[],
    public lat?: number,
    public lng?: number,
    public distance?: number,
    
    public dayOfWeek?: string,
    public mon?: boolean,
    public tue?: boolean,
    public wed?: boolean,
    public thu?: boolean,
    public fri?: boolean,
    public sat?: boolean,
    public sun?: boolean,
    
    public timeOfDay?: string,
    public after?: string,
    public before?: string,
    
    public userType?: string,
    public searchstring?: string
  ) {}
}
