import { Component, OnInit } from '@angular/core';
import { Setting } from 'app/shared/model/setting.model';
import { SettingService } from './setting.service';
import { AccountService, Account } from 'app/core';
import { forkJoin } from 'rxjs';
import { ProfileService } from '../profile/profile.service';
import { MapOption } from 'app/shared/model/mapOption.model';
import { isNullOrUndefined } from 'util';
import { HttpResponse } from '@angular/common/http';
import { GroupNotificationDiscussion } from 'app/shared/model/groupnotification-discussion.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gp-setting',
  templateUrl: './setting.component.html',
  styles: []
})
export class SettingComponent implements OnInit {
  error: boolean;
  success: boolean;
  setting: Setting;
  profileVisibility: MapOption[];
  groupOption: MapOption[];
  discussGroup:MapOption[];
  accountId: number;
  currentAccount: Account;

  constructor(private settingService: SettingService,
              private profileService: ProfileService,
              private accountService: AccountService
              ) {}

  ngOnInit() {
    this.accountService.changeUsername(localStorage.getItem('loggedinUsername'));
    this.setting = new Setting();
    this.setProbility();
    this.setGroupOption();
    this.accountService.identity().then(account => {
      forkJoin([this.settingService.findByUser(account.id), 
                this.profileService.findByUser(account.id),
                this.settingService.findDiscussionGroupByOwner(account.id)])
      .subscribe(result => {
        // this.accountId = account.id;
        this.currentAccount = account;
        this.setting.userId = account.id;
        this.setting.userLogin = account.login;
        this.setting.privateMessage = result[0].body.privateMessage;
        this.setting.profileVisibility = result[1].body.visibility;
        this.setting.discussionGroup = result[2];

        if (!isNullOrUndefined(result[0].body.id)) {
          this.findGroupNotification(result[0].body.id);
        }
      });
    });
  }

  findGroupNotification(id: number) {
    this.settingService.findGroupNotificationByNotificationId(id, {
      page: 0,
      size: 1000
    }).pipe(map((res: HttpResponse<GroupNotificationDiscussion[]>) => res.body))
    .subscribe(res => {
      this.setting.discussionGroup.map(item => {
        const index = res.findIndex(s => s.groupId === item.id);
        item.notificationId = id;
        item.schedule = res[index].schedule;
        return item;
      })
    })
  }


  save() {
    this.settingService.saveForm(this.setting).subscribe(
      () => {
        this.success = true;
        this.error = null;
      },
      () => {
        this.success = null;
        this.error = true;
      }
    );
  }

  setProbility() {
    this.profileVisibility = [];
    this.profileVisibility.push({key: 'PUBLIC', value:'public'});
    this.profileVisibility.push({key: 'MEMBER_ONLY', value:'member only'});
    this.profileVisibility.push({key: 'DISABLED', value:'disabled'});
  }

  setGroupOption() {
    this.groupOption = [];
    this.groupOption.push({key: 'IMMEDIATE', value:'immediate'});
    this.groupOption.push({key: 'DAILY', value:'daily'});
    this.groupOption.push({key: 'WEEKLY', value:'weekly'});
  }
}
