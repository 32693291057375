
    <div id="strength">
      <small>Password strength:</small>
      <ul id="strengthBar">
        <li class="point"></li>
        <li class="point"></li>
        <li class="point"></li>
        <li class="point"></li>
        <li class="point"></li>
      </ul>
    </div>
  