import { FeedbackType } from './enumerations/feedback-type.model';
import { IUser } from './user.model';

export interface IFeedBack {
  id?: number;
  title?: string;
  message?: string;
  type?: FeedbackType;
  name?: string;
  email?: string;
  receiverId?: number;
  senderId?: number;
  receiver?: IUser;
}

export class FeedBack implements IFeedBack {
  constructor(
    public id?: number,
    public title?: string,
    public message?: string,
    public type?: FeedbackType,
    public name?: string,
    public email?: string,
    public receiverId?: number,
    public senderId?: number,
    public receiver?: IUser
  ) {
    this.id = id || null;
    this.title = title || 'REPORT';
    this.message = message || null;
    this.type = type || FeedbackType.REPORT_PROFILE;
    this.name = name || null;
    this.email = email || null;
    this.receiverId = receiverId || null;
    this.senderId = senderId || null;
  }
}
