import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { UserType } from 'app/shared/model/enumerations/user-type.model';
import { DashboardService } from '../dashboard/dashboard.service';
import { IUser } from 'app/shared/model/user.model';
import { AdmindashboardService } from './admindashboard.service';
import { FeedbackType } from 'app/shared/model/enumerations/feedback-type.model';
import { ReportType } from 'app/shared/model/enumerations/report-type.model';
import { IFeedBack } from 'app/shared/model/feed-back.model';
import { ProfileModalService } from '../profile/profile-modal.service';
import { AccountService, Account } from 'app/core';
import { ProfileService } from '../profile/profile.service';
import { Status } from 'app/shared/model/enumerations/status.model';
import { ISpeciality } from 'app/shared/model/speciality.model';
import { Router } from '@angular/router';
import { IReportPost } from 'app/shared/model/report-post.model';

@Component({
  selector: 'gp-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.scss']
})
export class AdmindashboardComponent implements OnInit {
  gp: IUser[];
  specialists: IUser[];
  aliedHealth: IUser[];
  feedBacks: IFeedBack[];
  countFeedBackUser: number;
  countGp: number;
  countSpecialists: number;
  countaliedHealth: number;
  countSpecialities: number;
  currentAccount: Account;
  countReportPost: number;
  specialities: ISpeciality[];
  reportPost: IReportPost[];
  constructor(
    private dashboardService: DashboardService,
    private adminDashBoardService: AdmindashboardService,
    private accountService: AccountService,
    private profileModalService: ProfileModalService,
    private profileService: ProfileService,
    private route: Router

  ) {}
  ngOnInit() {
    this.accountService.changeUsername(localStorage.getItem('loggedinUsername'));
    this.countFeedBackUser = 0;
    this.countGp = 0;
    this.countSpecialists = 0;
    this.countaliedHealth = 0;
    this.countSpecialities = 0;
    this.countReportPost = 0;
    this.loadAll();
    this.accountService.identity().then(account => {
      this.currentAccount = account;
    });
  }

  loadAll() {
    forkJoin([
      this.dashboardService.findUserByUserType({
        page: 0,
        size: 5,
        sort: ['createdDate,desc'],
        type: UserType.SPECIALIST
      }),
      this.dashboardService.findUserByUserType({
        page: 0,
        size: 5,
        sort: ['createdDate,desc'],
        type: UserType.APPLIED_HEALTH
      }),
      this.dashboardService.findUserByUserType({
        page: 0,
        size: 5,
        sort: ['createdDate,desc'],
        type: UserType.GP
      }),
      this.adminDashBoardService.findAllByType({
        page: 0,
        size: 5,
        types: `${FeedbackType.REPORT_PROFILE},${FeedbackType.USER_REPORT}`
      }),
      this.profileService.findAllPrimary({
        isPrimary: true,
        status: Status.APPROVED,
        page: 0,
        size: 5,
        sort: ['createdDate,desc']
      }),
      this.adminDashBoardService.findReportPost({
        types: `${ReportType.REPORT_GROUP},${ReportType.QA}`,
        page: 0,
        size: 5,
        sort: ['createdDate,desc']
      })
    ]).subscribe(([res1, res2, res3, res4, res5, res6]) => {
      this.specialists = res1.body;
      this.aliedHealth = res2.body;
      this.gp = res3.body;
      this.feedBacks = res4.body;
      this.specialities = res5.body;
      this.reportPost = res6.body;
    });
  }

  findFeedBackUser() {
    this.adminDashBoardService
      .findAllByType({
        page: this.countFeedBackUser,
        size: 5,
        types: `${FeedbackType.REPORT_PROFILE},${FeedbackType.USER_REPORT}`
      })
      .subscribe(res => {
        this.feedBacks = this.feedBacks.concat(res.body);
      });
  }

  findUserGp() {
    this.dashboardService
      .findUserByUserType({
        page: this.countGp,
        size: 5,
        sort: ['createdDate,desc'],
        type: UserType.GP
      })
      .subscribe(res => {
        this.gp = this.gp.concat(res.body);
      });
  }

  findUserSpecialist() {
    this.dashboardService
      .findUserByUserType({
        page: this.countSpecialists,
        size: 5,
        sort: ['createdDate,desc'],
        type: UserType.SPECIALIST
      })
      .subscribe(res => {
        this.specialists = this.specialists.concat(res.body);
      });
  }

  findUserAliedHealth() {
    this.dashboardService
      .findUserByUserType({
        page: this.countaliedHealth,
        size: 5,
        sort: ['createdDate,desc'],
        type: UserType.APPLIED_HEALTH
      })
      .subscribe(res => {
        this.aliedHealth = this.aliedHealth.concat(res.body);
      });
  }

  findSpecialities() {
    this.profileService
      .findAllPrimary({
        isPrimary: false,
        status: Status.APPROVED,
        page: this.countSpecialities,
        size: 5,
        sort: ['createdDate,desc']
      })
      .subscribe(res => {
        this.specialities = this.specialities.concat(res.body);
      });
  }

  findReportPost() {
    this.adminDashBoardService.findReportPost({
      types: `${ReportType.REPORT_GROUP},${ReportType.QA}`,
      page: this.countReportPost,
      size: 5,
      sort: ['createdDate,desc']
    })
      .subscribe(res => {
        this.reportPost = this.reportPost.concat(res.body);
      });
  }

  onModalScrollDown() {
    this.countFeedBackUser++;
    this.findFeedBackUser();
  }

  onScrollGp() {
    this.countGp++;
    this.findUserGp();
  }

  onScrollSpecialist() {
    this.countSpecialists++;
    this.findUserSpecialist();
  }

  onScrollAliedHealth() {
    this.countaliedHealth++;
    this.findUserAliedHealth();
  }

  onScrollSpeciality() {
    this.countSpecialities++;
    this.findSpecialities();
  }

  onScrollReportPost() {
    this.countReportPost++;
    this.findReportPost();
  }

  sendMessage(receiverId) {
    this.profileModalService.openSendMessage(receiverId, this.currentAccount.id, this.handleSendMessage);
  }

  suspendUser(userId: number) {
    if (confirm("Do you want suspend this user?")) {
      this.adminDashBoardService.suspendUser(userId).subscribe(res => {
        this.loadAll();
      })
    }
  }

  linkToProfileBylogin(login: string) {
    this.adminDashBoardService.findUserByLogin(login).subscribe(res => {
      this.route.navigate([`admin/profile/${res.id}`]);
    })
  }

  deleteGroupId(groupId: number) {
    if (confirm("Do you want delete this group?")) {
      this.adminDashBoardService.deleteGroupId(groupId).subscribe(res => {
        this.loadAll();
      })
    }
  }

  handleSendMessage = res => {};
}
