import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { filter, map } from 'rxjs/operators';
import { JhiEventManager, JhiParseLinks, JhiDataUtils } from 'ng-jhipster';
import { Observable } from 'rxjs';

import { IEndorsement, Endorsement } from 'app/shared/model/endorsement.model';
import { AccountService } from 'app/core/auth/account.service';

import { ITEMS_PER_PAGE } from 'app/shared/constants/pagination.constants';
import { EndorsementService } from './endorses.service';

@Component({
  selector: 'endorses',
  templateUrl: './endorses.html'
})
export class EndorsesComponent implements OnInit, OnDestroy {
  
  public filterString: string;

  currentAccount: any;
  endorsements: IEndorsement[];
  error: any;
  success: any;
  eventSubscriber: Subscription;
  routeData: any;
  links: any;
  totalItems: any;
  itemsPerPage: any;
  page: any;
  // predicate: any;
  previousPage: any;
  reverse: any;
  currentItem: IEndorsement;

  isSaving: boolean;
  isCreating: boolean;

  constructor(
      protected endorsementService: EndorsementService,
      protected parseLinks: JhiParseLinks,
      protected accountService: AccountService,
      protected activatedRoute: ActivatedRoute,
      protected dataUtils: JhiDataUtils,
      protected router: Router,
      protected eventManager: JhiEventManager
    ) {
      this.itemsPerPage = ITEMS_PER_PAGE;
      this.routeData = this.activatedRoute.data.subscribe(data => {
        if (data.pagingParams) {
          this.page = data.pagingParams.page;
          this.previousPage = data.pagingParams.page;
          this.reverse = data.pagingParams.ascending;
          // this.predicate = data.pagingParams.predicate;
        }
      });
    }

    loadAll() {
      if (!this.page) {
        this.page = 1;
      }
      this.endorsementService
        .query({
          page: this.page - 1,
          size: this.itemsPerPage
          // sort: this.sort()
        })
        .subscribe((res: HttpResponse<IEndorsement[]>) => this.paginateEndorsement(res.body, res.headers));
    }

    loadPage(page: number) {
      if (page !== this.previousPage) {
        this.previousPage = page;
        this.transition();
      }
    }

    transition() {
//      this.router.navigate(['/endorses'], {
//        queryParams: {
//          page: this.page,
//          size: this.itemsPerPage
//          // sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
//        }
//      });
//      this.loadAll();
      
      this.endorsementService
      .query({
        page: this.page - 1,
        size: this.itemsPerPage
        // sort: this.sort()
      })
      .subscribe((res: HttpResponse<IEndorsement[]>) => this.paginateEndorsement(res.body, res.headers));

    }

    clear() {
      this.page = 1;
//      this.router.navigate([
//        '/endorses',
//        {
//          page: this.page
//          // sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
//        }
//      ]);
//      this.loadAll();
      this.endorsementService
      .query({
        page: this.page - 1,
        // sort: this.sort()
      })
      .subscribe((res: HttpResponse<IEndorsement[]>) => this.paginateEndorsement(res.body, res.headers));

    }

    ngOnInit() {
      this.isSaving = false;
      this.isCreating = false;
      this.currentItem = new Endorsement();
      this.loadAll();
      this.accountService.identity().then(account => {
        this.currentAccount = account;
      });
      this.registerChangeInEndorsement();
    }

    ngOnDestroy() {
      this.eventManager.destroy(this.eventSubscriber);
    }

    trackId(index: number, item: IEndorsement) {
      return item.id;
    }

    byteSize(field) {
      return this.dataUtils.byteSize(field);
    }

    openFile(contentType, field) {
      return this.dataUtils.openFile(contentType, field);
    }

    registerChangeInEndorsement() {
      this.eventSubscriber = this.eventManager.subscribe('endorsementListModification', response => this.loadAll());
    }

    /* sort() {
      const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
      if (this.predicate !== 'id') {
        result.push('id');
      }
      return result;
    } */

    protected paginateEndorsement(data: IEndorsement[], headers: HttpHeaders) {
      this.links = this.parseLinks.parse(headers.get('link'));
      this.totalItems = parseInt(headers.get('X-Total-Count'), 10);
      this.endorsements = data;
    }
    
    clearForm() {
      this.currentItem = new Endorsement();
    }
    
    validateEditForm() {
      if(confirm("Are you sure to apply changes?")) {
        this.save();
      }
    }
    
    findSimilar() {
      // eslint-disable-next-line  no-console
      console.log('findSimilar');
    }
    
    save() {
      this.isSaving = true;
      if (this.currentItem.id) {
        this.subscribeToSaveResponse(this.endorsementService.update(this.currentItem));
      } else {
        this.isCreating = true;
        this.subscribeToSaveResponse(this.endorsementService.create(this.currentItem));
      }
    }

    protected subscribeToSaveResponse(result: Observable<HttpResponse<IEndorsement>>) {
      result.subscribe((data: any) => {
        this.onSaveSuccess();
        if (this.isCreating) {
          // eslint-disable-next-line  no-console
          console.log(data.body);
          // this.endorsements.push(data.body);
          // this.totalItems++;
          this.loadAll();
          this.currentItem = new Endorsement();
          this.isCreating = false;
        }
      }, () => this.onSaveError());
    }

    protected onSaveSuccess() {
      this.isSaving = false;
    }

    protected onSaveError() {
      this.isSaving = false;
    }
    
    deleteRow(endorsement: IEndorsement) {
      if(confirm("Are you sure you want to delete item ID " + endorsement.id + "?")) {
        this.endorsementService.delete(endorsement.id).subscribe(response => {
          // eslint-disable-next-line  no-console
          console.log(response);
          // this.endorsements.splice(this.endorsements.indexOf(endorsement) ,1);
          // this.totalItems--;
          this.loadAll();
        });
      }
    }
    
    scrollToTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

}
