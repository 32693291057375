import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService, Account } from 'app/core';

@Component({
  selector: 'gp-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit, AfterViewInit, OnDestroy {
  errorMessage: string;
  error403: boolean;
  error404: boolean;
  account: Account;

  constructor(private route: ActivatedRoute, private accountService: AccountService) {}

  ngOnInit() {
    this.route.data.subscribe(routeData => {
      if (routeData.error403) {
        this.error403 = routeData.error403;
      }
      if (routeData.error404) {
        this.error404 = routeData.error404;
      }
      if (routeData.errorMessage) {
        this.errorMessage = routeData.errorMessage;
      }
    });
  }

  ngAfterViewInit() {
    const body = document.getElementsByTagName('body')[0];
    this.accountService.identity().then((account: Account) => {
      if (!account) {
        body.classList.add('landing-page');
      } else {
        body.classList.remove('landing-page');
      }
    });
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('landing-page');
  }
}
