import { Component, OnInit, OnDestroy } from '@angular/core';
import { JhiEventManager } from 'ng-jhipster';

import { AccountService, Account, LoginService, StateStorageService, RegisterTypeModalService } from 'app/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'gp-home',
  templateUrl: './home.component.html',
  styleUrls: ['home.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  account: Account;
  modalRef: NgbModalRef;

  authenticationError: boolean;

  loginForm = this.fb.group({
    username: [''],
    password: [''],
    rememberMe: [true]
  });

  constructor(
    private accountService: AccountService,
    private loginService: LoginService,
    private eventManager: JhiEventManager,
    private stateStorageService: StateStorageService,
    protected registerTypeModalService: RegisterTypeModalService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.accountService.identity().then((account: Account) => {
      if (account) {
        this.account = account;
        this.router.navigate(['./admin/dashboard']);
      }
    });
    this.registerAuthenticationSuccess();

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('homepage');
  }

  registerAuthenticationSuccess() {
    this.eventManager.subscribe('authenticationSuccess', message => {
      this.accountService.identity().then(account => {
        this.account = account;
      });
    });
  }

  isAuthenticated() {
    return this.accountService.isAuthenticated();
  }

  login() {
    this.loginService
      .login({
        username: this.loginForm.get('username').value,
        password: this.loginForm.get('password').value,
        rememberMe: this.loginForm.get('rememberMe').value
      })
      .then(() => {
        this.authenticationError = false;
        if (
          this.router.url.startsWith('/registration') ||
          this.router.url.startsWith('/about') ||
          this.router.url.startsWith('/activate') ||
          this.router.url.startsWith('/reset')
        ) {
          this.router.navigate(['']);
        }

        this.eventManager.broadcast({
          name: 'authenticationSuccess',
          content: 'Sending Authentication Success'
        });

        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
        // since login is successful, go to stored previousState and clear previousState
        const redirect = this.stateStorageService.getUrl();
        if (redirect) {
          this.stateStorageService.storeUrl(null);
          this.router.navigateByUrl(redirect);
        }

        this.router.navigate(['/admin/dashboard']);
      })
      .catch(() => {
        this.authenticationError = true;
      });
  }

  selectRegisterType() {
    this.modalRef = this.registerTypeModalService.open();
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('homepage');
  }
}
