import { IEndorsementUser } from './endorsement-user.model';
import { INewAliedHealth } from './new-alied-health.model';
import { INewPrivateMessages } from './new-private-messages.model';
import { ISpeciality } from './speciality.model';
import { IDiscussionGroup } from './discussion-group.model';

export interface IDashboard {
  endorsermentUser?: IEndorsementUser[];
  newSpecialists?: ISpeciality[];
  newAliedHealth?: INewAliedHealth;
  publicGroupPost?: IDiscussionGroup[];
  newPrivateMessages?: INewPrivateMessages[];
}

export class Dashboard implements IDashboard {
  constructor(
    public endorsermentUser?: IEndorsementUser[],
    public newSpecialists?: ISpeciality[],
    public newAliedHealth?: INewAliedHealth,
    public publicGroupPost?: IDiscussionGroup[],
    public newPrivateMessages?: INewPrivateMessages[]
  ) {
    this.endorsermentUser = endorsermentUser ? endorsermentUser : [];
    this.newSpecialists = newSpecialists ? newSpecialists : [];
    this.newAliedHealth = newAliedHealth ? newAliedHealth : null;
    this.publicGroupPost = publicGroupPost ? publicGroupPost : [];
    this.newPrivateMessages = newPrivateMessages ? newPrivateMessages : [];
  }
}
