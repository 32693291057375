import { Route } from '@angular/router';

import { SearchComponent } from './search.component';

export const searchRoute: Route = {
  path: 'search',
  component: SearchComponent,
  data: {
    pageTitle: 'Specialist search'
  }
};
