import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISpeciality } from 'app/shared/model/speciality.model';
import { ProfileService } from 'app/admin/profile/profile.service';
import { Status } from 'app/shared/model/enumerations/status.model';
import { isNullOrUndefined } from 'util';
import { ReviewSpecialititesService } from '../../review-specialitites.service';

@Component({
  selector: 'gp-edit-speciality',
  templateUrl: './edit-speciality.component.html',
  styleUrls: ['./edit-speciality.component.scss']
})
export class EditSpecialityComponent implements OnInit {
  @Input()
  speciality: any;

  spec: ISpeciality;
  primaries: ISpeciality[];
  hasPrimary: boolean;
  constructor(
    public activeModal: NgbActiveModal,
    public profileService: ProfileService,
    public reviewService: ReviewSpecialititesService
  ) {}

  ngOnInit() {
    this.spec = Object.assign(JSON.parse(JSON.stringify(this.speciality)));
    this.hasPrimary = false;
    if (!isNullOrUndefined(this.spec.primaryId)) {
      this.hasPrimary = true;
      this.profileService
        .findAllPrimary({
          isPrimary: true,
          status: Status.APPROVED,
          page: 0,
          size: 1000
        })
        .subscribe(res => {
          this.primaries = res.body;
        });
    }
  }

  editSpeciality() {
    this.reviewService.editSpeciality(this.spec).subscribe(res => {
      this.activeModal.close(true);
    });
  }
}
