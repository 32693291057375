import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { JhiParseLinks, JhiDataUtils } from 'ng-jhipster';

import { IPosts, Posts } from 'app/shared/model/posts.model';
import { AccountService } from 'app/core/auth/account.service';

import { PostsService } from './posts.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { SERVER_API_URL } from 'app/app.constants';
import { CKEUploadAdapter } from 'app/admin/posts/cke-upload-adapter';

@Component({
  selector: 'gp-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['posts.component.scss']
})
export class PostsComponent implements OnInit {
  public filterString: string;

  posts: IPosts[];
  currentAccount: any;
  itemsPerPage: number;
  links: any;
  page: any;
  totalItems: number;

  curritem: IPosts;
  public Editor = ClassicEditor;
  showhtml = false;

  public uploadUrl = SERVER_API_URL + 'api/front/upload';

  public editorConfig = {
    placeholder: 'type the text ...',
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'imageUpload', 'blockQuote', 'undo', 'redo']
  };

  constructor(
    protected postsService: PostsService,
    protected dataUtils: JhiDataUtils,
    protected parseLinks: JhiParseLinks,
    protected accountService: AccountService
  ) {
    this.posts = [];
    this.itemsPerPage = 5;
    this.page = 0;
    this.links = {
      last: 0
    };
  }

  loadAll() {
    this.curritem = new Posts();
    this.curritem.postContent = null;
    this.postsService
      .findall({
        page: this.page,
        size: this.itemsPerPage
      })
      .subscribe((res: HttpResponse<IPosts[]>) => this.paginatePosts(res.body, res.headers));
  }

  reset() {
    this.page = 0;
    this.posts = [];
    this.loadAll();
  }

  loadPage(page) {
    this.page = page;
    this.loadAll();
  }

  ngOnInit() {
    this.loadAll();
    this.accountService.identity().then(account => {
      this.currentAccount = account;
    });
    this.accountService.changeUsername(localStorage.getItem('loggedinUsername'));
  }

  byteSize(field) {
    return this.dataUtils.byteSize(field);
  }

  openFile(contentType, field) {
    return this.dataUtils.openFile(contentType, field);
  }

  protected paginatePosts(data: IPosts[], headers: HttpHeaders) {
    this.links = this.parseLinks.parse(headers.get('link'));
    this.totalItems = parseInt(headers.get('X-Total-Count'), 10);
    for (let i = 0; i < data.length; i++) {
      this.posts.push(data[i]);
    }
  }

  onClickItem(post: IPosts) {
    this.curritem = post;
  }

  postContent() {
    if (this.curritem.id) {
      this.postsService.update(this.curritem).subscribe(() => {
        this.reset();
      });
    } else {
      this.postsService.create(this.curritem).subscribe(() => {
        this.reset();
      });
    }
  }

  public onEditorChange({ editor }: ChangeEvent) {
    this.curritem.postContent = editor.getData();
  }

  public onReady(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new CKEUploadAdapter(loader, {
        uploadUrl: this.uploadUrl
      });
    };
  }

  deletePost() {
    const message = 'Please confirm delete post ' + this.curritem.postName + ' with id ' + this.curritem.id;
    if (confirm(message)) {
      if (this.curritem.id) {
        this.postsService.delete(this.curritem.id).subscribe(() => {
          this.reset();
        });
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(message + ' cancel by user.');
    }
  }
}
