import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';
import { Observable } from 'rxjs';
import { ISpeciality } from 'app/shared/model/speciality.model';
import { createRequestOption } from 'app/shared';

type EntityResponseType = HttpResponse<ISpeciality[]>;
@Injectable({
  providedIn: 'root'
})
export class ReviewSpecialititesService {
  private resourceUrl = SERVER_API_URL + 'api/front/specialities';

  constructor(private service: HttpClient) {}

  findAllSpeciality(req?: any): Observable<EntityResponseType> {
    const options = createRequestOption(req);
    return this.service.get<ISpeciality[]>(`${this.resourceUrl}/admin`, { params: options, observe: 'response' });
  }

  deleteSpecialityById(id: number): Observable<any> {
    return this.service.delete(`${this.resourceUrl}/${id}`);
  }

  editSpeciality(speciality: ISpeciality): Observable<any> {
    return this.service.put(this.resourceUrl, speciality);
  }

  approvedSpeciality(speciality: ISpeciality): Observable<any> {
    return this.service.put(`${this.resourceUrl}/approved`, speciality);
  }

  mergeSpeciality(speciality: ISpeciality, specialityMerge: number): Observable<any> {
    return this.service.put(`${this.resourceUrl}/${specialityMerge}/merged`, speciality);
  }
}
