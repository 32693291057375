import { Component, OnInit, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { isNullOrUndefined } from 'util';
import { IMedicalPractice, MedicalPractice } from 'app/shared/model/medical-practice.model';
import { isEmpty } from 'app/shared';

@Component({
  selector: 'gp-medical-practice-modal',
  templateUrl: './medical-practice-modal.component.html',
  styles: [
    `
      agm-map {
        height: 250px;
      }
      ::ng-deep .pac-container {
        z-index: 9999;
      }

      ::ng-deep .timepicker {
        z-index: 9999 !important;
      }

      ::ng-deep .timepicker-backdrop-overlay {
        z-index: 9999 !important;
      }
      ::ng-deep .timepicker-overlay {
        z-index: 9999 !important;
      }
      .badge-size {
        font-size: smaller;
      }
      .border-time {
        border: 1px solid #eee;
        border-radius: 10px;
      }
      .cursor-pointer {
        cursor: pointer;
      }
    `
  ]
})
export class MedicalPracticeModalComponent implements OnInit {
  @Input() public inputModel;

  medicalPractice: IMedicalPractice;
  medicalBefore: IMedicalPractice;
  private geoCoder;
  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;
  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}

  ngOnInit() {
    this.updateForm(this.inputModel);
    this.loadMap();
  }

  loadMap() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.medicalPractice.lat = place.geometry.location.lat();
          this.medicalPractice.lng = place.geometry.location.lng();
          this.getAddress(this.medicalPractice.lat, this.medicalPractice.lng);
        });
      });
    });
  }

  private setCurrentLocation() {
    if (!isNullOrUndefined(this.medicalPractice.id)) return;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.medicalPractice.lat = isNullOrUndefined(this.medicalPractice.lat) ? position.coords.latitude : this.medicalPractice.lat;
        this.medicalPractice.lng = isNullOrUndefined(this.medicalPractice.lng) ? position.coords.longitude : this.medicalPractice.lng;
        this.getAddress(this.medicalPractice.lat, this.medicalPractice.lng);
      });
    }
  }

  isRequired() {
    if (isEmpty(this.medicalPractice.name)) {
      return true;
    }
    return false;
  }

  markerDragEnd($event: MouseEvent) {
    this.medicalPractice.lat = $event.coords.lat;
    this.medicalPractice.lng = $event.coords.lng;
    this.getAddress(this.medicalPractice.lat, this.medicalPractice.lng);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          // this.zoom = 18;
          this.medicalPractice.line = results[0].formatted_address;
          this.getDetailAddress(results);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getDetailAddress(results: any) {
    /* eslint-disable no-console */
    console.log('Address result=', results);
    if (results.length) {
      this.medicalPractice.number = null;
      this.medicalPractice.street = null;
      this.medicalPractice.postCode = null;
      this.medicalPractice.country = null;
      this.medicalPractice.locality = null;
      this.medicalPractice.city = null;
      this.medicalPractice.state = null;
    }
    for (let i = 0; i < results.length; i++) {

      for (let j = 0; j < results[i].address_components.length ;j ++) {
        const str = results[i].address_components[j].types[0];
        if (str === 'street_number' && isNullOrUndefined(this.medicalPractice.number)) {
          this.medicalPractice.number = results[i].address_components[j].long_name;
          continue;
        }

        if (str === 'route' && isEmpty(this.medicalPractice.street)) {
          this.medicalPractice.street = results[i].address_components[j].long_name;
          continue;
        }
        
        if (str === 'locality' && isEmpty(this.medicalPractice.locality)) {
          this.medicalPractice.locality = results[i].address_components[j].long_name;
          continue;
        }

        if (results[i].address_components[j].long_name.indexOf("City") >= 0 && isEmpty(this.medicalPractice.city)) {
          this.medicalPractice.city = results[i].address_components[j].long_name;
          continue;
        }

        if (str === 'postal_code' && isEmpty(this.medicalPractice.postCode)) {
          this.medicalPractice.postCode = results[i].address_components[j].long_name;
          continue;
        }

        if (str === 'administrative_area_level_1' && isEmpty(this.medicalPractice.state)) {
          this.medicalPractice.state = results[i].address_components[j].long_name;
          continue;
        }

        if (str === 'country' && isEmpty(this.medicalPractice.country)) {
          this.medicalPractice.country = results[i].address_components[j].long_name;
          continue;
        }
      }
    }
  }

  save() {
    this.activeModal.close(this.medicalPractice);
  }

  close () {
    this.activeModal.close(this.medicalBefore);
  }

  updateForm(medicalPracticeModel) {
    if (isNullOrUndefined(medicalPracticeModel.id)) {
      this.medicalPractice = new MedicalPractice();
    }
    this.medicalPractice = medicalPracticeModel;
    this.medicalBefore = JSON.parse(JSON.stringify(this.medicalPractice));
  }

  onChangeTime(val: string, field: string) {
    this.medicalPractice[field] = val;
  }

  formatHHMM(decimalTimeString) {
    if (decimalTimeString === "" || isNullOrUndefined(decimalTimeString)) return "";
    const hoursMinute = decimalTimeString.split(':');
    let hours : any = hoursMinute[0];
    let minutes: any = hoursMinute[1];
    if (+hoursMinute[0] < 10) {
      hours = "0" + hoursMinute[0];
    }

    if (+hoursMinute[1] < 10) {
      minutes = "0" + hoursMinute[1];
    }
    return hours + ":" + minutes
  }
}
