import { HttpParams } from '@angular/common/http';
import { isNullOrUndefined } from 'util';

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();
  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort' && req[key] !== null && req[key] !== undefined) {
        options = options.set(key, req[key]);
      }
    });
    if (req.sort) {
      req.sort.forEach(val => {
        options = options.append('sort', val);
      });
    }
  }
  return options;
};

export const splitStr = (name: string, length: number): string => {
  if (isNullOrUndefined(name)) {
    return name;
  }
  if (name.length >= length) {
    return name.substr(0, length).concat('...');
  }
  return name;
};

export const isEmpty = (text: string): boolean => {
  if (isNullOrUndefined(text) || text === '') {
    return true;
  }
  return false;
};
