import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { GpplcrSharedModule } from 'app/shared';

import {
  PasswordStrengthBarComponent,
  RegisterComponent,
  ActivateComponent,
  PasswordComponent,
  PasswordResetInitComponent,
  PasswordResetFinishComponent,
  SettingsComponent,
  InvitationComponent,
  accountState
} from './';

@NgModule({
  imports: [
    GpplcrSharedModule, 
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDI5Dfq3AHTao3m-w7Q9Sssa6GRYqmGS9I',
      libraries: ['places']
    }),
    RouterModule.forChild(accountState)
  ],
  declarations: [
    ActivateComponent,
    RegisterComponent,
    PasswordComponent,
    PasswordStrengthBarComponent,
    PasswordResetInitComponent,
    PasswordResetFinishComponent,
    SettingsComponent,
    InvitationComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GpplcrAccountModule {}
