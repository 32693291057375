import { Component, OnInit } from '@angular/core';
import { ReviewSpecialititesService } from './review-specialitites.service';
import { ISpeciality } from 'app/shared/model/speciality.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../confirm/delete-modal/delete-modal.component';
import { EditSpecialityComponent } from './modal/edit-speciality/edit-speciality.component';
import { MergeSpecialityComponent } from './modal/merge-speciality/merge-speciality.component';
import { AccountService } from 'app/core';

@Component({
  selector: 'gp-review-specialities',
  templateUrl: './review-specialities.component.html',
  styleUrls: ['./review-specialities.component.scss']
})
export class ReviewSpecialitiesComponent implements OnInit {
  specialitiesApprove: ISpeciality[];
  specialitiesUnapprove: ISpeciality[];
  pageApprove: number;
  previousPageApprove: any;
  reverseApprove: any;
  predicateApprove: any;
  sortApprove: string;
  totalItemsApprove: any;

  pageUnapprove: number;
  reverseUnapprove: any;
  previousPageUnapprove: any;
  sortUnapprove: string;
  predicateUnapprove: any;
  totalItemsUnapprove: any;
  routeData: any;
  constructor(
    private reviewService: ReviewSpecialititesService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private modalService: NgbModal
  ) {
    this.routeData = this.activatedRoute.data.subscribe(data => {
      this.pageApprove = data.pagingParams.page;
      this.previousPageApprove = data.pagingParams.page;
      this.reverseApprove = data.pagingParams.ascending;
      this.predicateApprove = data.pagingParams.predicate;

      this.pageUnapprove = data.pagingParams.page;
      this.previousPageUnapprove = data.pagingParams.page;
      this.reverseUnapprove = data.pagingParams.ascending;
      this.predicateUnapprove = data.pagingParams.predicate;
    });
  }

  ngOnInit() {
    this.sortApprove = 'createdDate,desc';
    this.sortUnapprove = 'createdDate,desc';
    this.accountService.changeUsername(localStorage.getItem('loggedinUsername'));
    this.findAllByApprove();
    this.findAllByUnapprove();
  }

  findAllByApprove() {
    this.reviewService
      .findAllSpeciality({
        status: `${Status.APPROVED}`,
        page: this.pageApprove - 1,
        size: 10,
        sort: this.sort(this.predicateApprove, this.reverseApprove)
      })
      .subscribe(res => {
        this.paginateApprove(res.body, res.headers);
      });
  }

  findAllByUnapprove() {
    this.reviewService
      .findAllSpeciality({
        status: `${Status.UNAPPROVED}`,
        page: this.pageUnapprove - 1,
        size: 10,
        sort: this.sort(this.predicateUnapprove, this.reverseUnapprove)
      })
      .subscribe(res => {
        this.paginateUnApprove(res.body, res.headers);
      });
  }

  transitionApprove() {
    this.findAllByApprove();
  }

  transitionUnapprove() {
    this.findAllByUnapprove();
  }

  sort(predicate, reverse) {
    const result = [predicate + ',' + (reverse ? 'asc' : 'desc')];
    if (predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  protected paginateApprove(data: ISpeciality[], headers: HttpHeaders) {
    this.totalItemsApprove = parseInt(headers.get('X-Total-Count'), 10);
    this.specialitiesApprove = data;
  }

  protected paginateUnApprove(data: ISpeciality[], headers: HttpHeaders) {
    this.totalItemsUnapprove = parseInt(headers.get('X-Total-Count'), 10);
    this.specialitiesUnapprove = data;
  }

  approvedSpeciality(speciality: ISpeciality) {
    if (confirm(`Do you want ${speciality.name} approve`)) {
      this.reviewService.approvedSpeciality(speciality).subscribe(res => {
        this.findAllByApprove();
        this.findAllByUnapprove();
      });
    }
  }

  loadPageApprove(page: number) {
    if (page !== this.previousPageApprove) {
      this.previousPageApprove = page;
      this.transitionApprove();
    }
  }

  loadPageUnapprove(page: number) {
    if (page !== this.previousPageUnapprove) {
      this.previousPageUnapprove = page;
      this.transitionUnapprove();
    }
  }

  deleteSpeciality(id: number, isApprove: boolean) {
    this.modalService.open(DeleteModalComponent, { size: 'sm', backdrop: 'static' }).result.then(res => {
      if (res === true) {
        this.reviewService.deleteSpecialityById(id).subscribe(response => {
          if (isApprove) {
            this.findAllByApprove();
          } else {
            this.findAllByUnapprove();
          }
        });
      }
    });
  }

  editSpeciality(speciality: ISpeciality, isApprove: boolean) {
    const modalRef = this.modalService.open(EditSpecialityComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.speciality = speciality;
    modalRef.result.then(res => {
      if (res === true) {
        if (isApprove) {
          this.findAllByApprove();
        } else {
          this.findAllByUnapprove();
        }
      }
    });
  }

  mergeSpeciality(speciality: ISpeciality) {
    const modalRef = this.modalService.open(MergeSpecialityComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.speciality = speciality;
    modalRef.result.then(res => {
      if (res === true) {
        this.findAllByApprove();
        this.findAllByUnapprove();
      }
    });
  }
}
