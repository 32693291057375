import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FilterPipe } from 'app/shared/util/filter.pipe';
import { SafeHtmlPipe } from 'app/shared/util/safeHtml.pipe';
import { GpplcrSharedLibsModule, GpplcrSharedCommonModule, HasAnyAuthorityDirective, RegisterModalComponent } from './';

@NgModule({
  imports: [GpplcrSharedLibsModule, GpplcrSharedCommonModule],
  declarations: [FilterPipe, SafeHtmlPipe, RegisterModalComponent, HasAnyAuthorityDirective],
  entryComponents: [RegisterModalComponent],
  exports: [FilterPipe, SafeHtmlPipe, GpplcrSharedCommonModule, RegisterModalComponent, HasAnyAuthorityDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GpplcrSharedModule {
  static forRoot() {
    return {
      ngModule: GpplcrSharedModule
    };
  }
}
