import { Moment } from 'moment';
import { Status } from 'app/shared/model/enumerations/status.model';
import { IUser, User } from '../../shared/model/user.model';
import { IDiscussionUser } from './discussion-user.model';

export interface IDiscussionGroup {
  id?: number;
  name?: string;
  thumbnail?: string;
  description?: any;
  status?: Status;
  isPublic?: boolean;
  ownerId?: number;
  ownerLogin?: string;
  discussion?: IDiscussionUser[];
  user?: IUser;
  createdDate?: Moment;
}

export class DiscussionGroup implements IDiscussionGroup {
  constructor(
    public id?: number,
    public name?: string,
    public thumbnail?: string,
    public description?: any,
    public status?: Status,
    public isPublic?: boolean,
    public ownerId?: number,
    public ownerLogin?: string,
    public user?: IUser,
    public discussion?: IDiscussionUser[],
    public createdDate?: Moment
  ) {
    this.id = this.id || null;
    this.name = this.name || null;
    this.thumbnail = this.thumbnail || null;
    this.description = this.description || null;
    this.status = this.status || Status.INACTIVE;
    this.isPublic = this.isPublic || false;
    this.ownerId = this.ownerId || null;
    this.ownerLogin = this.ownerLogin || null;
    this.user = this.user || new User();
    this.discussion = this.discussion || [];
    this.createdDate = this.createdDate || null;
  }
}
