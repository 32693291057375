import { Route } from '@angular/router';

import { UserRouteAccessService } from 'app/core';
import { InvitationComponent } from 'app/account/invitation/invitation.component';

export const invitationRoute: Route = {
  path: 'invite',
  component: InvitationComponent,
  data: {
    authorities: ['ROLE_USER'],
    pageTitle: 'Send Invitation Email'
  },
  canActivate: [UserRouteAccessService]
};
