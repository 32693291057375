import { Component, OnInit, ViewChild, ElementRef, NgZone  } from '@angular/core';
import { LoginService, AccountService, Account } from 'app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchResultDashboardService } from 'app/admin/search-result-dashboard/search-result-dashboard.service';
import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'gp-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss']
})
export class SearchHeaderComponent implements OnInit {
  isNavbarCollapsed: boolean;
  currentAccount: Account;
  location = '';
  speciality = '';
  allSpecialities = [];
  resourceSpecialitiesUrl = SERVER_API_URL + 'api/front/allSpecialities';
  lat?: number;
  lng?: number;
  
  private geoCoder;

  @ViewChild("search", { static: false }) public searchElementRef: ElementRef;
  
  constructor(
    private http: HttpClient,
    protected loginService: LoginService,
    protected accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    protected router: Router,
    protected searchDashboardService: SearchResultDashboardService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.isNavbarCollapsed = true;
    this.accountService.identity().then(account => {
      this.currentAccount = account;
    });
    this.location = this.activatedRoute.snapshot.queryParamMap.get('location');
    this.speciality = this.activatedRoute.snapshot.queryParamMap.get('speciality');
    this.getAllSpecialities();
    this.loadPlacesAutocomplete();
  }

  isAuthenticated() {
    return this.accountService.isAuthenticated();
  }

  searchMedical() {
    const data = {
      location: this.location,
      speciality: this.speciality,
      lat: this.lat,
      lng: this.lng
    };
//    const routeCurrent = this.router.url.split('/');
//    if (routeCurrent.length >= 2 && routeCurrent[2].split('?')[0] !== 'search-result') {
//      this.router.navigate(['admin/search-result'], { queryParams: data });
//    } else {
//      this.searchDashboardService.next(JSON.stringify(data));
//    }
    this.router.navigate(['admin/search'], { queryParams: data });
  }
  
  getAllSpecialities() {
    this.http.get(this.resourceSpecialitiesUrl).subscribe((res: any[]) => {
      this.allSpecialities = res;
    });
  }
  
  // Load Places Autocomplete reference https://www.freakyjolly.com/angular-7-6-add-google-maps-in-angular-2-plus-applications-using-angular-google-maps-module-agm-core-easily/
  loadPlacesAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["geocode"], // https://developers.google.com/places/web-service/autocomplete#place_types
        // componentRestrictions: {country: 'au'}
      });
      
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          
          // set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          // eslint-disable-next-line no-console
          console.log('Address result=', place);
          this.location = place.formatted_address;
          // this.getAddress(this.lat, this.lng);
        });
      });
      
    });
  }
  
  /* getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[5]) {
          this.location = results[5].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  } */
  
}
