import { Route } from '@angular/router';

import { SearchResultDashboardComponent } from './search-result-dashboard.component';

export const searchResultDashboardRoute: Route = {
  path: 'search-result',
  component: SearchResultDashboardComponent,
  data: {
    pageTitle: 'Search'
  }
};
