import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ProdConfig } from './blocks/config/prod.config';
import { GpplcrAppModule } from './app.module';

ProdConfig();

if (module['hot']) {
  module['hot'].accept();
}

/* eslint-disable no-console */
platformBrowserDynamic()
  .bootstrapModule(GpplcrAppModule, { preserveWhitespaces: true })
  .then(success => console.log(`Application started`))
  .catch(err => console.error(err));
/* eslint-enable no-console */
