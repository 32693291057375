import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '../../app.constants';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDiscussionGroup } from 'app/shared/model/discussion-group.model';
import { createRequestOption } from 'app/shared';
import { IUser } from 'app/shared/model/user.model';
type EntityPublicGroupPost = HttpResponse<IDiscussionGroup[]>;
type EntityUserProfile = HttpResponse<IUser[]>;
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private resourceUrl = SERVER_API_URL + 'api/dashboard';
  public profileUrl = SERVER_API_URL + 'api/front/profiles';
  public resourcePublicGroupUrl = SERVER_API_URL + 'api/discussion-groups/follows';
  constructor(private http: HttpClient) {}

  getPublicGroupPosts(req?: any): Observable<EntityPublicGroupPost> {
    const options = createRequestOption(req);
    return this.http.get<IDiscussionGroup[]>(this.resourcePublicGroupUrl, { params: options, observe: 'response' });
  }

  findUserBySpecilityType(req?: any): Observable<EntityUserProfile> {
    const options = createRequestOption(req);
    return this.http.get<IUser[]>(`${this.profileUrl}/specialityType`, { params: options, observe: 'response' });
  }
  
  findUserByUserType(req?: any): Observable<EntityUserProfile> {
    const options = createRequestOption(req);
    return this.http.get<IUser[]>(`${this.profileUrl}/userType`, { params: options, observe: 'response' });
  }

}
