import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '../../message.service';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { IUser } from 'app/shared/model/user.model';
import { IMessageGroup, MessageGroup } from 'app/shared/model/message-group.model';
import { IMessage, Message } from 'app/shared/model/message.model';
import { isEmpty } from 'app/shared';

@Component({
  selector: 'gp-new-message-modal',
  templateUrl: './new-message-modal.component.html',
  styleUrls: ['./new-message-modal.component.scss']
})
export class NewMessageModalComponent implements OnInit {
  messages: string;
  textSearch: string;
  users: IUser[];
  result: number[];
  title: string;
  @Input()
  senderId: number;
  constructor(public activeModal: NgbActiveModal, 
    public messageService: MessageService) {}

  ngOnInit() {
    this.users = [];
    this.searchUser();
  }

  searchUser() {
    this.messageService
      .findUser(this.senderId)
      .pipe(map((res: HttpResponse<IUser[]>) => res.body))
      .subscribe(res => {
        res = res.map(item => {
          item.fullName = item.firstName + ' ' + item.lastName;
          return item;
        });
        this.users = this.users.concat(res);
      });
  }

  createGroup() {
    const group: IMessageGroup = new MessageGroup();
    // group.message = this.messages;
    group.senderId = this.senderId;
    this.result.push(this.senderId);
    group.receiverIds = this.result.join(',');
    group.title = this.title;
    this.messageService.newMessageGroup(group).subscribe(res => {
      const content: IMessage = new Message();
      content.message = this.messages;
      content.groupId = res.id;
      content.senderId = this.senderId;
      content.title = this.title;
      this.messageService.sendMessage(content).subscribe(res2 => {
        this.activeModal.close(true);
      });
    });
    
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.fullName.toLocaleLowerCase().indexOf(term) > -1 
  }

  checkValid() {
    if (isEmpty(this.messages) || this.result.length === 0 || isEmpty(this.title)) {
      return true;
    }
    return false;
  }
}
