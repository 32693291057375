import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'gp-message-read-modal',
  templateUrl: './message-read-modal.component.html',
  styles: []
})
export class MessageReadModalComponent implements OnInit {
  @Input() public message: any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  passBack() {
    this.activeModal.close(this.message);
  }
}
