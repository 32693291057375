import { Route } from '@angular/router';

import { JhiDashboardComponent } from './dashboard.component';

export const dashboardRoute: Route = {
  path: 'dashboard',
  component: JhiDashboardComponent,
  data: {
    pageTitle: 'Dashboard'
  }
};
