import { Moment } from 'moment';

export interface IPosts {
  id?: number;
  postTitle?: string;
  postDate?: Moment;
  postContent?: any;
  postExcerpt?: string;
  postStatus?: string;
  postName?: string;
  postParent?: number;
  postType?: string;
  guid?: string;
  menuOrder?: number;
}

export class Posts implements IPosts {
  constructor(
    public id?: number,
    public postTitle?: string,
    public postDate?: Moment,
    public postContent?: any,
    public postExcerpt?: string,
    public postStatus?: string,
    public postName?: string,
    public postParent?: number,
    public postType?: string,
    public guid?: string,
    public menuOrder?: number
  ) {}
}
