import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IFeedBack, FeedBack } from 'app/shared/model/feed-back.model';
import { User } from 'app/core/user/user.model';

@Component({
  selector: 'gp-report-post-modal',
  templateUrl: './report-post-modal.component.html',
  styleUrls: ['./report-post-modal.component.scss']
})
export class ReportPostModalComponent implements OnInit {

  receiver: User;
  sender: number;
  @Input()
  set userReceive(val: User) {
    this.receiver = val;
  }

  get userReceive() {
    return this.receiver;
  }

  @Input()
  set userSend(val: number) {
    this.sender = val;
  }

  get userSend() {
    return this.sender;
  }

  reportText: string;
  feedback: IFeedBack;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.feedback = new FeedBack();
    this.feedback.senderId = this.sender;
    this.feedback.receiverId = this.receiver.id;
    this.feedback.email = this.receiver.email;
    this.feedback.name = this.receiver.firstName + ' ' + this.receiver.lastName
  }

  passBack() {
    this.activeModal.close(this.feedback);
  }
}
