import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';
// import { IFeedback, Feedback } from 'app/shared/model/feedback.model';
import { IPosts } from 'app/shared/model/posts.model';

@Component({
  selector: 'gp-help',
  templateUrl: './help.component.html',
  styles: []
})
export class HelpComponent implements OnInit, OnDestroy {
  
  posts: IPosts[];
  contactLocationImagePost: IPosts;
  
  editForm = this.formBuilder.group({
    name: [null, [Validators.required]],
    email: [null, [Validators.email, Validators.required]],
    title: [null, [Validators.required]],
    message: [null, [Validators.required]],
    type: 'FEEDBACK',
    status: 'NEW',
  });
  
  errorMessage = '';

  constructor(
      private http: HttpClient, 
      private formBuilder: FormBuilder, 
      private router: Router
  ) { }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('landing-page');
  }
  
  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('landing-page');
    this.http.get<IPosts[]>(SERVER_API_URL + 'api/front/posttype/help', { observe: 'response' }).subscribe(
        (res: HttpResponse<IPosts[]>) => (this.posts = res.body)
    );
    this.http.get<IPosts>(SERVER_API_URL + 'api/front/postname/contact-location-image', { observe: 'response' }).subscribe(
        (res: HttpResponse<IPosts>) => (this.contactLocationImagePost = res.body)
    );
    
  }
  
  postContact(): void {
    this.http.post(SERVER_API_URL + 'api/front/feedback', this.editForm.value).subscribe((res: any[]) => {
      this.errorMessage = 'Thank you for your feedback!';
    },
    error => {
      this.errorMessage = error.message;
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
    );
  }
  
  postAgain(): void {
    this.errorMessage = '';
    this.editForm.reset();
  }
  
}
