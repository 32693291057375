import { Injectable, OnDestroy, Component } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MedicalPracticeModalComponent } from './modal/medical-practice-modal/medical-practice-modal.component';
import { SpecialityModalComponent } from './modal/speciality-modal/speciality-modal.component';
import { AboutModalComponent } from './modal/about-modal/about-modal.component';
import { NameModalComponent } from 'app/admin/profile/modal/name-modal/name-modal.component';
import { AhpraModalComponent } from 'app/admin/profile/modal/ahpra-modal/ahpra-modal.component';
import { EndorsementsModalComponent } from './modal/endorsements-modal/endorsements-modal.component';
import { SendMessageModalComponent } from './modal/send-message-modal/send-message-modal.component';
import { ShortnameModalComponent } from './modal/shortname-modal/shortname-modal.component';

@Injectable({ providedIn: 'root' })
export class ProfileModalService implements OnDestroy {
  private isOpen = false;
  protected currentModal: NgbModalRef;

  constructor(private modalService: NgbModal) {}
  
  openShortname(inputModel: String, callBack: Function) {
    this.currentModal = this.open(ShortnameModalComponent as Component, 'lg', callBack);
    this.currentModal.componentInstance.inputModel = inputModel;
  }

  openMedicalPractice(inputModel: any, callBack: Function) {
    this.currentModal = this.open(MedicalPracticeModalComponent as Component, 'lg', callBack);
    this.currentModal.componentInstance.inputModel = inputModel;
  }

  openSpeciality(inputModel: any, callBack: Function) {
    this.currentModal = this.open(SpecialityModalComponent as Component, 'lg', callBack);
    this.currentModal.componentInstance.userLogin = inputModel;
  }

  openAbout(inputModel: String, callBack: Function) {
    this.currentModal = this.open(AboutModalComponent as Component, 'lg', callBack);
    this.currentModal.componentInstance.inputModel = inputModel;
  }

  openAhpraNumber(inputModel: String, callBack: Function) {
    this.currentModal = this.open(AhpraModalComponent as Component, 'sm', callBack);
    this.currentModal.componentInstance.inputModel = inputModel;
  }

  openName(inputModel: any, callBack: Function) {
    this.currentModal = this.open(NameModalComponent as Component, 'sm', callBack);
    this.currentModal.componentInstance.inputModel = inputModel;
  }

  openEndorsements(endorUser: number, userId: number, inputModel: any, callBack: Function) {
    this.currentModal = this.open(EndorsementsModalComponent as Component, 'lg', callBack);
    this.currentModal.componentInstance.endorUser = endorUser;
    this.currentModal.componentInstance.userId = userId;
    this.currentModal.componentInstance.endorSement = inputModel;
  }

  openSendMessage(userProfile: number, userId: number, callBack: Function) {
    this.currentModal = this.open(SendMessageModalComponent as Component, 'lg', callBack);
    this.currentModal.componentInstance.userId = userId;
    this.currentModal.componentInstance.userProfile = userProfile;
  }

  open(component: Component, sizeModal: NgbModalOptions['size'] = 'sm', callBack: Function): NgbModalRef {
    if (this.isOpen) {
      // return;
      this.modalService.dismissAll();
    }
    this.isOpen = true;
    const modalRef = this.modalService.open(component, { size: sizeModal, backdrop: 'static' });
    modalRef.result.then(
      result => {
        this.isOpen = false;
        callBack(result);
        this.currentModal = null;
      },
      reason => {
        this.isOpen = false;
        this.currentModal = null;
      }
    );
    return modalRef;
  }

  ngOnDestroy() {
    this.currentModal = null;
  }
}
