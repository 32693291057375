import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '../../message.service';
import { IUserBasic } from 'app/shared/model/message-group.model';
import { Router } from '@angular/router';

@Component({
  selector: 'gp-receiver-modal',
  templateUrl: './receiver-modal.component.html',
  styleUrls: ['./receiver-modal.component.scss']
})
export class ReceiverModalComponent implements OnInit {
  @Input()
  receivers: IUserBasic[];
  constructor(public activeModal: NgbActiveModal, public messageService: MessageService, private route: Router) {}

  ngOnInit() {}

  linkToProfile(userId) {
    this.route.navigate([`admin/profile/${userId}`]);
    this.activeModal.close();
  }
}
