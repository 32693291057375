import { Moment } from 'moment';
import { IDiscussion } from 'app/shared/model/discussion.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { IUser } from './user.model';

export interface IDiscussion {
  id?: number;
  topic?: string;
  summary?: any;
  content?: any;
  members?: string;
  status?: Status;
  liked?: number;
  disliked?: number;
  isPublic?: boolean;
  closed?: boolean;
  replies?: IDiscussion[];
  discussionId?: number;
  groupId?: number;
  ownerId?: number;
  ownerLogin?: string;
  user?: IUser;
  createdDate?: Moment;
}

export class Discussion implements IDiscussion {
  constructor(
    public id?: number,
    public topic?: string,
    public summary?: any,
    public content?: any,
    public members?: string,
    public status?: Status,
    public liked?: number,
    public disliked?: number,
    public isPublic?: boolean,
    public closed?: boolean,
    public replies?: IDiscussion[],
    public discussionId?: number,
    public groupId?: number,
    public ownerId?: number,
    public ownerLogin?: string,
    public user?: IUser,
    public createdDate?: Moment
  ) {
    this.id = this.id || null;
    this.topic = this.topic || 'POST-GROUP';
    this.summary = this.summary || null;
    this.content = this.content || null;
    this.members = this.members || null;
    this.isPublic = this.isPublic || true;
    this.closed = this.closed || false;
    this.status = this.status || Status.INACTIVE;
    this.liked = this.liked || 0;
    this.disliked = this.disliked || 0;
    this.replies = this.replies || [];
    this.discussionId = this.discussionId || null;
    this.groupId = this.groupId || null;
    this.ownerId = this.ownerId || null;
    this.ownerLogin = this.ownerLogin || null;
    this.user = this.user || null;
    this.createdDate = this.createdDate || null;
  }
}
