import { Component, OnInit, OnDestroy } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';
import { IPosts } from 'app/shared/model/posts.model';

@Component({
  selector: 'gp-viewpage',
  template: `<div [innerHTML]="content | safeHtml"></div>`
})
export class ViewpageComponent implements OnInit, OnDestroy {
  
  content = '';
  
  constructor(
      private http: HttpClient, 
      private route: ActivatedRoute
  ) { }
  
  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('landing-page');
  }
  
  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('landing-page');
    const postName = this.route.snapshot.paramMap.get('postName');
    const requrl = SERVER_API_URL + 'api/front/postname/' + postName;
    this.http.get<IPosts>(requrl, { observe: 'response' }).subscribe(
        (res: HttpResponse<IPosts>) => (this.content = res.body.postContent),
        (err) => (this.content = "Page not found!")
    );
  }
  
}

export const viewpageRoute: Route = {
  path: 'viewpage/:postName',
  component: ViewpageComponent,
  data: {
    pageTitle: 'View page'
  }
};