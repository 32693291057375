import { Status } from './enumerations/status.model';

export interface IEndorsement {
  id?: number;
  name?: string;
  endorsedBy?: number;
  userId?: number;
  status?: Status;
  isChecked?: boolean;
  count?: number;
}

export class Endorsement implements IEndorsement {
  constructor(
    public id?: number,
    public name?: string,
    public endorsedBy?: number,
    public userId?: number,
    public status?: Status,
    public isChecked?: boolean,
    public count?: number
  ) {
    this.id = id || null;
    this.name = name || null;
    this.endorsedBy = endorsedBy || null;
    this.userId = userId || null;
    this.status = status || null;
    this.isChecked = isChecked || false;
  }
}
