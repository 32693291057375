import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GpplcrSharedModule } from 'app/shared';
/* jhipster-needle-add-admin-module-import - JHipster will add admin modules imports here */

import {
  adminState,
  AuditsComponent,
  UserMgmtComponent,
  UserMgmtDetailComponent,
  UserMgmtUpdateComponent,
  UserMgmtDeleteDialogComponent,
  LogsComponent,
  JhiMetricsMonitoringComponent,
  JhiHealthModalComponent,
  JhiHealthCheckComponent,
  JhiConfigurationComponent,
  JhiDocsComponent
  // JhiTrackerComponent
} from './';
import { JhiDashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { MedicalPracticeModalComponent } from './profile/modal/medical-practice-modal/medical-practice-modal.component';
import { ProfileModalService } from './profile/profile-modal.service';
import { SpecialityModalComponent } from './profile/modal/speciality-modal/speciality-modal.component';
import { AboutModalComponent } from './profile/modal/about-modal/about-modal.component';
import { ShortnameModalComponent } from './profile/modal/shortname-modal/shortname-modal.component';
import { MedicalPracticeComponent } from './profile/components/medical-practice.component';
import { MessagesComponent } from './messages/messages.component';
import { PostsComponent } from './posts/posts.component';
import { SettingComponent } from './setting/setting.component';
import { NameModalComponent } from 'app/admin/profile/modal/name-modal/name-modal.component';
import { AhpraModalComponent } from 'app/admin/profile/modal/ahpra-modal/ahpra-modal.component';
import { GroupsComponent } from './groups/groups.component';
import { CreateGroupComponent } from './groups/create-group/create-group.component';
import { GroupsModalService } from './groups/groups-modal.service';
import { ContextMenuModule } from 'ngx-contextmenu';
import { ReportPostModalComponent } from './groups/report-post-modal/report-post-modal.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EndorsementsModalComponent } from './profile/modal/endorsements-modal/endorsements-modal.component';
import { SendMessageModalComponent } from './profile/modal/send-message-modal/send-message-modal.component';
import { DeleteModalComponent } from './confirm/delete-modal/delete-modal.component';
import { NewMessageModalComponent } from './messages/modal/new-message-modal/new-message-modal.component';
import { MessageReadModalComponent } from './messages/modal/message-read-modal/message-read-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchComponent } from './search/search.component';
import { AdmindashboardComponent } from './admindashboard/admindashboard.component';
import { ReviewSpecialitiesComponent } from './review-specialities/review-specialities.component';
import { AgmCoreModule } from '@agm/core';
import { ReceiverModalComponent } from './messages/modal/receiver-modal/receiver-modal.component';
import { EditSpecialityComponent } from './review-specialities/modal/edit-speciality/edit-speciality.component';
import { MergeSpecialityComponent } from './review-specialities/modal/merge-speciality/merge-speciality.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { EndorsesComponent } from './endorses/endorses.component';
import { MdcprtComponent } from './Mdcprt/Mdcprt.component';
import { SpcltComponent } from './Spclt/Spclt.component';
import { ClickOutsideDirective } from './profile/modal/speciality-modal/ClickOutsideDirective';
import { SearchHeaderComponent } from './dashboard/search-header/search-header.component';
import { SearchResultDashboardComponent } from './search-result-dashboard/search-result-dashboard.component';

@NgModule({
  imports: [
    GpplcrSharedModule,
    /* jhipster-needle-add-admin-module - JHipster will add admin modules here */
    RouterModule.forChild(adminState),
    ContextMenuModule.forRoot(),
    InfiniteScrollModule,
    NgSelectModule,
    NgxMaterialTimepickerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDI5Dfq3AHTao3m-w7Q9Sssa6GRYqmGS9I',
      libraries: ['places']
    })
  ],
  declarations: [
    AuditsComponent,
    UserMgmtComponent,
    UserMgmtDetailComponent,
    UserMgmtUpdateComponent,
    UserMgmtDeleteDialogComponent,
    LogsComponent,
    JhiConfigurationComponent,
    JhiHealthCheckComponent,
    JhiHealthModalComponent,
    JhiDocsComponent,
    // JhiTrackerComponent,
    JhiMetricsMonitoringComponent,
    JhiDashboardComponent,
    ProfileComponent,
    MedicalPracticeModalComponent,
    NameModalComponent,
    AhpraModalComponent,
    SpecialityModalComponent,
    AboutModalComponent,
    ShortnameModalComponent,
    MedicalPracticeComponent,
    MessagesComponent,
    PostsComponent,
    SettingComponent,
    GroupsComponent,
    CreateGroupComponent,
    ReportPostModalComponent,
    EndorsementsModalComponent,
    SendMessageModalComponent,
    DeleteModalComponent,
    NewMessageModalComponent,
    MessageReadModalComponent,
    SearchComponent,
    AdmindashboardComponent,
    ReviewSpecialitiesComponent,
    ReceiverModalComponent,
    EditSpecialityComponent,
    MergeSpecialityComponent,
    EndorsesComponent,
    MdcprtComponent,
    SpcltComponent,
    ClickOutsideDirective,
    SearchResultDashboardComponent,
    SearchHeaderComponent
  ],
  entryComponents: [
    UserMgmtDeleteDialogComponent,
    JhiHealthModalComponent,
    MedicalPracticeModalComponent,
    NameModalComponent,
    AhpraModalComponent,
    SpecialityModalComponent,
    AboutModalComponent,
    ShortnameModalComponent,
    CreateGroupComponent,
    ReportPostModalComponent,
    EndorsementsModalComponent,
    SendMessageModalComponent,
    DeleteModalComponent,
    NewMessageModalComponent,
    MessageReadModalComponent,
    ReceiverModalComponent,
    EditSpecialityComponent,
    MergeSpecialityComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    MedicalPracticeModalComponent,
    SpecialityModalComponent,
    AboutModalComponent,
    ShortnameModalComponent,
    NameModalComponent,
    AhpraModalComponent,
    CreateGroupComponent,
    ReportPostModalComponent,
    EndorsementsModalComponent,
    SendMessageModalComponent,
    DeleteModalComponent,
    NewMessageModalComponent,
    MessageReadModalComponent,
    EditSpecialityComponent
  ],
  providers: [ProfileModalService, GroupsModalService]
})
export class GpplcrAdminModule {}
