import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { VERSION } from 'app/app.constants';
import { Account, AccountService, LoginService } from 'app/core';
import { ProfileService } from 'app/layouts/profiles/profile.service';

@Component({
  selector: 'gp-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['navbar.scss']
})
export class NavbarComponent implements OnInit {
  inProduction: boolean;
  swaggerEnabled: boolean;
  modalRef: NgbModalRef;
  version: string;
  isNavbarCollapsed: boolean;
  currentAccount: Account;

  constructor(
    protected loginService: LoginService,
    protected accountService: AccountService,
    protected profileService: ProfileService,
    protected router: Router
  ) {
    this.version = VERSION ? 'v' + VERSION : '';
  }

  ngOnInit() {
    this.isNavbarCollapsed = true;
    this.accountService.identity().then(account => {
      this.currentAccount = account;
    });
    this.profileService.getProfileInfo().then(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.swaggerEnabled = profileInfo.swaggerEnabled;
    });
  }

  isAuthenticated() {
    return this.accountService.isAuthenticated();
  }

  closeNavbar() {
    this.isNavbarCollapsed = true;
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  logout() {
    this.isNavbarCollapsed = true;
    this.loginService.logout();
    this.router.navigate(['/']);
  }

  getImageUrl() {
    return this.isAuthenticated() ? this.accountService.getImageUrl() : null;
  }
}
