import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReviewSpecialititesService } from '../../review-specialitites.service';
import { ProfileService } from 'app/admin/profile/profile.service';
import { Status } from 'app/shared/model/enumerations/status.model';
import { ISpeciality } from 'app/shared/model/speciality.model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'gp-merge-speciality',
  templateUrl: './merge-speciality.component.html',
  styleUrls: ['./merge-speciality.component.scss']
})
export class MergeSpecialityComponent implements OnInit {
  subs: ISpeciality[];
  specialityId: number;
  @Input()
  speciality: any;
  constructor(
    public activeModal: NgbActiveModal,
    public profileService: ProfileService,
    public reviewService: ReviewSpecialititesService
  ) {}

  ngOnInit() {
    this.profileService
      .findAllPrimary({
        isPrimary: false,
        status: Status.APPROVED,
        page: 0,
        size: 1000
      })
      .subscribe(res => {
        this.subs = res.body;
      });
  }

  isValid() {
    if (isNullOrUndefined(this.specialityId)) {
      return true;
    }
    return false;
  }

  mergeSpeciality() {
    if (this.isValid()) {
      return;
    }
    this.reviewService.mergeSpeciality(this.speciality, this.specialityId).subscribe(res => {
      this.activeModal.close(true);
    });
  }
}
