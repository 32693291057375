export interface IGroupFollow {
  id?: number;
  userId?: number;
  groupId?: number;
}

export class GroupFollow implements GroupFollow {
  constructor(public id?: number, public userId?: number, public groupId?: number) {
    this.id = id || null;
    this.userId = userId || null;
    this.groupId = groupId || null;
  }
}
