import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import * as noUiSlider from 'nouislider';
// import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';
import { ISrUser } from 'app/shared/model/sr-user.model';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { ISearchInfo, SearchInfo } from 'app/shared/model/search-info.model';
import { isEmpty } from 'app/shared';
import { isNullOrUndefined } from 'util';
import { AccountService } from 'app/core';
import { Router, ActivatedRoute } from '@angular/router';

let slider: any;

@Component({
  selector: 'gp-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {
  
  srUsers: ISrUser[];
  allSpecialities = [];
  resourceSpecialitiesUrl = SERVER_API_URL + 'api/front/allSpecialities';
  searchUserUrl = SERVER_API_URL + 'api/front/sr/usersByDistance';
  isInit = false;
  isRedirect = false;
  advancedSearch = false;
  
  searchInfo: ISearchInfo;
  
  public zoom = 17;
  
  private geoCoder;

  @ViewChild("search", { static: false }) public searchElementRef: ElementRef;

  constructor(
      private http: HttpClient, 
      private accountService: AccountService,
      private mapsAPILoader: MapsAPILoader,
      private ngZone: NgZone,
      private router: Router,
      private activatedRoute: ActivatedRoute    
  ) {}

  ngOnInit() {
    this.accountService.changeUsername(localStorage.getItem('loggedinUsername'));
    this.searchInfo = new SearchInfo();
    // default location Brisbane QLD, Australia -27.465833462008565, 153.02504452972948
    this.searchInfo.lat = -27.4658;
    this.searchInfo.lng = 153.0250;
    this.searchInfo.usualAvailability = 999;
    this.searchInfo.dayOfWeek = "any";
    this.searchInfo.timeOfDay = "any";
    this.searchInfo.userType = "ANY";
    this.getAllSpecialities();
    this.searchInfo.specialities = [];
    this.isInit = true;
    
    if (!isNullOrUndefined(this.activatedRoute.snapshot.queryParamMap.get('speciality'))) {
      this.searchInfo.specialities = [Number(this.activatedRoute.snapshot.queryParamMap.get('speciality'))];
      this.isRedirect = true;
    }
    if (!isNullOrUndefined(this.activatedRoute.snapshot.queryParamMap.get('location'))) {
      this.searchInfo.searchstring = this.activatedRoute.snapshot.queryParamMap.get('location');
      this.isRedirect = true;
    }
    if (!isNullOrUndefined(this.activatedRoute.snapshot.queryParamMap.get('lat'))) {
      this.searchInfo.lat = Number(this.activatedRoute.snapshot.queryParamMap.get('lat'));
    }
    if (!isNullOrUndefined(this.activatedRoute.snapshot.queryParamMap.get('lng'))) {
      this.searchInfo.lng = Number(this.activatedRoute.snapshot.queryParamMap.get('lng'));
    }
    
    this.loadPlacesAutocomplete();
    
  }
  
  ngAfterViewInit() {
    slider = document.getElementById('slider-pips');
    noUiSlider.create(slider, {
      range: {
        min: 5,
        max: 35
      },
      start: [10],
      step: 5,
      pips: { mode: 'count', values: 7 }
    });

    slider.noUiSlider.on('change', function() {
      this.zoom = 17 - (slider.noUiSlider.get() / 5);
    });
    
    if (this.isRedirect) {
      this.startSearch();
    }
  }
  
  // Load Places Autocomplete reference https://www.freakyjolly.com/angular-7-6-add-google-maps-in-angular-2-plus-applications-using-angular-google-maps-module-agm-core-easily/
  loadPlacesAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      if (!this.isRedirect) {
        this.setCurrentPosition();
      }
      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["geocode"], // https://developers.google.com/places/web-service/autocomplete#place_types
        // componentRestrictions: {country: 'au'}
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          
          // set latitude, longitude and zoom
          this.searchInfo.lat = place.geometry.location.lat();
          this.searchInfo.lng = place.geometry.location.lng();
          this.zoom = 17;
          this.searchInfo.searchstring = place.formatted_address;
          this.getAddress(this.searchInfo.lat, this.searchInfo.lng);
        });
      });
    });
  }
  
  markerDragEnd($event: MouseEvent) {
    this.searchInfo.lat = $event.coords.lat;
    this.searchInfo.lng = $event.coords.lng;
    this.getAddress(this.searchInfo.lat, this.searchInfo.lng);
    // eslint-disable-next-line no-console
    console.log('markerDragEnd $event=', $event);
  }
  
  startSearch(): void {
    this.searchInfo.distance = slider.noUiSlider.get();
    this.http.post(this.searchUserUrl, this.searchInfo).subscribe((res: any[]) => {
      this.srUsers = res;
    });
  }

  getAllSpecialities() {
    this.http.get(this.resourceSpecialitiesUrl).subscribe((res: any[]) => {
      this.allSpecialities = res;
    });
  }
  
  setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.searchInfo.lat = position.coords.latitude;
        this.searchInfo.lng = position.coords.longitude;
        this.zoom = 17;
        this.getAddress(this.searchInfo.lat, this.searchInfo.lng);
      });
    }
  }
  
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 17;
          // this.searchInfo.searchstring = results[0].formatted_address;
          this.getDetailAddress(results);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  
  getDetailAddress(results: any) {
    // eslint-disable-next-line no-console
    console.log('Address result=', results);
    if (results.length) {
      this.searchInfo.number = null;
      this.searchInfo.street = null;
      this.searchInfo.locality = null;
      this.searchInfo.postCode = null;
      this.searchInfo.country = null;
      this.searchInfo.city = null;
      this.searchInfo.state = null;
      this.searchInfo.searchstring = results[0].formatted_address;
    }
    for (let i = 0; i < results.length; i++) {

      for (let j = 0; j < results[i].address_components.length ;j ++) {
        const str = results[i].address_components[j].types[0];
        if (str === 'street_number' && isNullOrUndefined(this.searchInfo.number)) {
          this.searchInfo.number = results[i].address_components[j].long_name;
          continue;
        }

        if (str === 'route' && isEmpty(this.searchInfo.street)) {
          this.searchInfo.street = results[i].address_components[j].long_name;
          continue;
        }
        
        if (str === 'locality' && isEmpty(this.searchInfo.locality)) {
          this.searchInfo.locality = results[i].address_components[j].long_name;
          continue;
        }

        if (results[i].address_components[j].long_name.indexOf("City") >= 0 && isEmpty(this.searchInfo.city)) {
          this.searchInfo.city = results[i].address_components[j].long_name;
          continue;
        }

        if (str === 'postal_code' && isEmpty(this.searchInfo.postCode)) {
          this.searchInfo.postCode = results[i].address_components[j].long_name;
          continue;
        }

        if (str === 'administrative_area_level_1' && isEmpty(this.searchInfo.state)) {
          this.searchInfo.state = results[i].address_components[j].long_name;
          continue;
        }

        if (str === 'country' && isEmpty(this.searchInfo.country)) {
          this.searchInfo.country = results[i].address_components[j].long_name;
          continue;
        }
      }
    }
  }
  
}
