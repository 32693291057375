import { NgModule } from '@angular/core';

import { GpplcrSharedLibsModule, JhiAlertComponent, JhiAlertErrorComponent } from './';

@NgModule({
  imports: [GpplcrSharedLibsModule],
  declarations: [JhiAlertComponent, JhiAlertErrorComponent],
  exports: [GpplcrSharedLibsModule, JhiAlertComponent, JhiAlertErrorComponent]
})
export class GpplcrSharedCommonModule {}
