import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { IMedicalPractice } from 'app/shared/model/medical-practice.model';
import { createRequestOption } from 'app/shared';
import { IDiscussionGroup } from 'app/shared/model/discussion-group.model';

type MedicalPracticesEntityArrayResponseType = HttpResponse<IMedicalPractice[]>;
type DiscussionGroupResponseType = HttpResponse<IDiscussionGroup[]>;

@Injectable({
  providedIn: 'root'
})
export class SearchResultDashboardService {
  private medicalPracticeUrl = SERVER_API_URL + 'api/front/medical-practices/matching';
  private discussionGroupUrl = SERVER_API_URL + 'api/discussion-groups/matching';

  private searchSubject = new BehaviorSubject('');

  constructor(protected http: HttpClient) {}

  public getSubject = (): Observable<any> => {
    return this.searchSubject.asObservable();
  };
  public next = (q: string) => {
    this.searchSubject.next(q);
  };

  public matchMedicalPractice(req: any): Observable<MedicalPracticesEntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IMedicalPractice[]>(this.medicalPracticeUrl, { params: options, observe: 'response' });
  }

  public matchDiscussionGroup(req: any): Observable<DiscussionGroupResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IDiscussionGroup[]>(this.discussionGroupUrl, { params: options, observe: 'response' });
  }
}
