import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISpeciality, Speciality } from 'app/shared/model/speciality.model';
import { ProfileService } from '../../profile.service';
import { isNullOrUndefined } from 'util';
import { isEmpty } from 'app/shared';
import { Status } from 'app/shared/model/enumerations/status.model';

@Component({
  selector: 'gp-speciality-modal',
  templateUrl: './speciality-modal.component.html',
  styles: [
    `
      .combobox {
        margin: auto;
      }

      .combobox-input {
        width: 330px;
        border: none;
        padding: 10px;
        font-size: 18px;
        border-bottom: 2px solid rgb(165, 112, 112);
      }

      .combobox-input:focus {
        outline-style: none;
      }

      .combobox-options {
        position: absolute;
        background-color: white;
        width: 430px;
        max-height: 200px;
        overflow-y: auto;
        box-shadow: 2px 2px 2px 2px #ccc;
        z-index: 1;
      }

      .selected {
        color: cornsilk;
        background-color: rgb(165, 112, 112);
      }

      list-item {
        display: block;
        font-size: 18px;
        padding: 10px;
      }

      list-item:hover {
        background-color: grey;
        color: white;
      }

      .parent {
        margin-left: 0px;
        font-weight: bold;
      }

      .children {
        margin-left: 30px;
      }
    `
  ]
})
export class SpecialityModalComponent implements OnInit {
  type: string;
  arrSubPrimary: ISpeciality[];
  subs: ISpeciality[];
  mouseOutDiv: boolean;
  textSearch: string;
  isNew: boolean;
  specialityId: number;
  sourceSpeciality: ISpeciality[];
  hiddenCbb: boolean;
  selectedIndex: number;
  primaryName: string;
  primaryId: number;
  primaries: ISpeciality[];
  @Input()
  userLogin: string;
  constructor(public activeModal: NgbActiveModal, private profileService: ProfileService) {}

  ngOnInit() {
    this.type = '0';
    this.selectedIndex = -1;
    this.isNew = false;
    this.hiddenCbb = true;
    this.primaryName = null;
    this.profileService
      .findAllPrimary({
        isPrimary: true,
        status: `${Status.APPROVED},${Status.UNAPPROVED}`,
        page: 0,
        size: 1000
      })
      .subscribe(res => {
        this.arrSubPrimary = res.body;
        this.primaries = Object.assign(JSON.parse(JSON.stringify(this.arrSubPrimary)));
        const arrSpeciality: ISpeciality[] = [];
        for (const item of this.arrSubPrimary) {
          if (!this.checkStatus(item)) {
            continue;
          }
          arrSpeciality.push({ id: item.id, name: item.name });
          if (!isNullOrUndefined(item.subs) && item.subs.length > 0) {
            for (const res1 of item.subs) {
              if (!this.checkStatus(res1)) {
                continue;
              }
              arrSpeciality.push({ id: res1.id, name: res1.name, primaryId: item.id, primaryName: item.name });
            }
          }
        }
        this.arrSubPrimary = arrSpeciality;
        this.sourceSpeciality = Object.assign(JSON.parse(JSON.stringify(arrSpeciality)));
      });
  }

  checkStatus(speciality: ISpeciality) {
    if (speciality.status === Status.APPROVED) {
      return true;
    }
    if (speciality.status === Status.UNAPPROVED && speciality.createdBy === this.userLogin) {
      return true;
    }
    return false;
  }

  save() {
    const special: ISpeciality = new Speciality();
    special.name = this.textSearch;
    if (this.isNew) {
      if (this.type === '1') {
        special.primaryId = this.primaryId;
      }
    } else {
      special.id = this.specialityId;
    }
    this.activeModal.close(special);
  }

  isValid(text): boolean {
    if (isNullOrUndefined(text)) {
      return true;
    }
    return false;
  }

  onKeyUpSearch(event) {
    if (!this.hiddenCbb) {
      if (event.key === 'Escape') {
        this.selectedIndex = -1;
        this.toggleListDisplay(0);
      }

      if (event.key === 'Enter') {
        this.toggleListDisplay(0);
      }
      if (event.key === 'ArrowDown') {
        this.hiddenCbb = false;
        this.selectedIndex = (this.selectedIndex + 1) % this.arrSubPrimary.length;
        if (this.arrSubPrimary.length > 0 && !this.hiddenCbb) {
          document.getElementsByTagName('list-item')[this.selectedIndex].scrollIntoView();
        }
      } else if (event.key === 'ArrowUp') {
        this.hiddenCbb = false;
        if (this.selectedIndex <= 0) {
          this.selectedIndex = this.arrSubPrimary.length;
        }
        this.selectedIndex = (this.selectedIndex - 1) % this.arrSubPrimary.length;

        if (this.arrSubPrimary.length > 0 && !this.hiddenCbb) {
          document.getElementsByTagName('list-item')[this.selectedIndex].scrollIntoView();
        }
      }
    }
  }

  onMouseDiv(flg: boolean) {
    this.mouseOutDiv = flg;
  }

  toggleListDisplay(sender: number) {
    if (sender === 1) {
      this.hiddenCbb = false;
      this.getFilteredList();
    } else {
      if (this.mouseOutDiv) return;
      setTimeout(() => {
        this.hiddenCbb = true;
        if (this.sourceSpeciality.filter(item => item.name.includes(this.textSearch)).length === 0) {
          this.arrSubPrimary = this.sourceSpeciality;
        }
      }, 500);
    }
  }

  getFilteredList() {
    this.hiddenCbb = false;
    if (!this.hiddenCbb && !isNullOrUndefined(this.textSearch)) {
      this.arrSubPrimary = this.sourceSpeciality.filter(item => item.name.includes(this.textSearch));
      const listCompare = this.arrSubPrimary.filter(item => item.name === this.textSearch);
      if (listCompare.length === 0) {
        this.specialityId = null;
        this.isNew = true;
      } else {
        this.isNew = false;
      }
    }
    if (isNullOrUndefined(this.textSearch) || this.textSearch === '') {
      this.isNew = false;
    }
  }

  selectItem(index: number) {
    // this.textSearch = null;
    this.specialityId = null;
    if (!isNullOrUndefined(this.arrSubPrimary[index])) {
      this.specialityId = this.arrSubPrimary[index].id;
      this.textSearch = this.arrSubPrimary[index].name;
      this.primaryName = this.arrSubPrimary[index].primaryName;
    }
    this.hiddenCbb = true;
    this.selectedIndex = index;
    this.isNew = false;
  }

  validSave() {
    if (!this.isNew) {
      if (isNullOrUndefined(this.specialityId) || isEmpty(this.primaryName)) {
        return true;
      }
      return false;
    } else {
      if (isEmpty(this.textSearch)) {
        return true;
      }
      if (this.type === '1' && isNullOrUndefined(this.primaryId)) {
        return true;
      }
      return false;
    }
  }
}
