export interface IFavourities {
    id?: number;
    userId?: number;
    profileId?: number;
}

export class Favourities implements IFavourities {
    constructor(public id?: number,
                public userId?: number,
                public profileId?: number) {
                    this.id = id || null;
                    this.userId = userId || null;
                    this.profileId = profileId || null;
                }
}
