import { Injectable } from '@angular/core';
import { SERVER_API_URL } from 'app/app.constants';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { createRequestOption } from 'app/shared';
import { IMessage } from 'app/shared/model/message.model';
import { Observable } from 'rxjs';
import { IMessageGroup } from 'app/shared/model/message-group.model';
import { isNullOrUndefined } from 'util';
import { IUser } from 'app/shared/model/user.model';

type EntityMessageType = HttpResponse<IMessage[]>;
type EntityMessageGroupType = HttpResponse<IMessageGroup[]>;
type EntityUserType = HttpResponse<IUser[]>
@Injectable({
  providedIn: 'root'
})
export class MessageService {
  resourceUrl = SERVER_API_URL + 'api/messages';
  messageGroupUrl = SERVER_API_URL + 'api/message-groups';
  userUrl = SERVER_API_URL + 'api/front/profiles'
  constructor(private http: HttpClient) {}

  findUser(userId: number): Observable<EntityUserType> {
    return this.http.get<IUser[]>(`${this.userUrl}/${userId}/matching`, {observe: 'response'});
  }

  newMessageGroup(messageGroup: IMessageGroup): Observable<any> {
    return this.http.post(this.messageGroupUrl, messageGroup);
  }

  sendMessage(message: IMessage): Observable<any> {
    return this.http.post(this.resourceUrl, message);
  }

  findMessageByGroup(req?: any): Observable<EntityMessageType> {
    const options = createRequestOption(req);
    return this.http.get<IMessage[]>(`${this.resourceUrl}/group`, { params: options, observe: 'response' });
  }

  findGroupMessageHaveMe(textSearch: string, req?: any): Observable<EntityMessageGroupType> {
    let options = createRequestOption(req);
    if (!isNullOrUndefined(textSearch) && textSearch !== '') {
      options = options.set('textSearch', textSearch);
    }
    return this.http.get<IMessageGroup[]>(`${this.messageGroupUrl}/me`, { params: options, observe: 'response' });
  }

  findMessageNew(req?: any): Observable<EntityMessageType> {
    const options = createRequestOption(req);
    return this.http.get<IMessage[]>(`${this.resourceUrl}/new`, { params: options, observe: 'response' });
  }
  
  markAsRead(messageId: number): Observable<any> {
    const reqUrl = SERVER_API_URL + 'api/messages/read/' + messageId;
    return this.http.get(reqUrl);
  }
  
  countUnreadMessagesByUser(userId: number): Observable<any> {
    const reqUrl = SERVER_API_URL + 'api/messages/unreads/' + userId;
    return this.http.get(reqUrl);
  }
  
}
