import { Routes } from '@angular/router';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { ProfileComponent } from 'app/admin/profile/profile.component';

export const profileRoute: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      authorities: ['ROLE_USER'],
      pageTitle: 'Profile'
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'profile/:id',
    component: ProfileComponent,
    data: {
      authorities: ['ROLE_USER'],
      pageTitle: 'Profile'
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'user/:shortname',
    component: ProfileComponent,
    data: {
      authorities: ['ROLE_USER'],
      pageTitle: 'Profile'
    },
    canActivate: [UserRouteAccessService]
  }
];
