import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { IMedicalPractice } from 'app/shared/model/medical-practice.model';
import { Subscription } from 'rxjs';
import { IDiscussionGroup } from 'app/shared/model/discussion-group.model';
import { AccountService } from 'app/core/auth/account.service';
import { Account } from 'app/core';
import { SearchResultDashboardService } from './search-result-dashboard.service';

@Component({
  selector: 'gp-search-result-dashboard',
  templateUrl: './search-result-dashboard.component.html',
  styleUrls: ['./search-result-dashboard.component.scss']
})
export class SearchResultDashboardComponent implements OnInit, OnDestroy {
  location = '';
  speciality = '';
  links: any;

  private readonly SIZE = 10;

  totalItems: any;
  totalGroup: any;

  page = 0;
  pageGroup = 0;

  medicalPractices: IMedicalPractice[];
  discussionGroup: IDiscussionGroup[];

  buttonSelected = 0;

  subsciption: Subscription;
  currentAccount: Account;

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private searchDashboardService: SearchResultDashboardService
  ) {}

  ngOnDestroy(): void {
    this.subsciption.unsubscribe();
  }

  ngOnInit() {
    this.subsciption = this.searchDashboardService.getSubject().subscribe(res => {
      if (res !== '') {
        const data = JSON.parse(res);
        this.location = data.location;
        this.speciality = data.speciality;
      }
      this.initData();
    });
    this.location = this.activatedRoute.snapshot.queryParamMap.get('location');
    this.speciality = this.activatedRoute.snapshot.queryParamMap.get('speciality');
    this.initData();
    this.accountService.identity().then(account => {
      this.currentAccount = account;
    });
  }

  initData() {
    this.medicalPractices = [];
    this.discussionGroup = [];
    this.page = 0;
    this.pageGroup = 0;
    this.matchMedical();
    this.matchDiscussionGroup();
  }

  matchMedical() {
    this.searchDashboardService
      .matchMedicalPractice({
        location: this.location,
        speciality: this.speciality,
        page: this.page,
        size: this.SIZE,
        sort: ['createdDate,desc']
      })
      .subscribe((res: HttpResponse<IMedicalPractice[]>) => this.paginateMedicalPractices(res.body, res.headers));
  }

  matchDiscussionGroup() {
    this.searchDashboardService
      .matchDiscussionGroup({
        q: this.location,
        page: this.page,
        size: this.SIZE,
        sort: ['createdDate,desc']
      })
      .subscribe((res: HttpResponse<IDiscussionGroup[]>) => this.paginateDiscussionGroup(res.body, res.headers));
  }

  protected paginateDiscussionGroup(data: IDiscussionGroup[], headers: HttpHeaders) {
    this.totalGroup = parseInt(headers.get('X-Total-Count'), 10);
    if (this.pageGroup > 0) {
      this.discussionGroup = this.discussionGroup.concat(data);
    } else {
      this.discussionGroup = data;
    }
  }

  protected paginateMedicalPractices(data: IMedicalPractice[], headers: HttpHeaders) {
    this.totalItems = parseInt(headers.get('X-Total-Count'), 10);
    if (this.page > 0) {
      this.medicalPractices = this.medicalPractices.concat(data);
    } else {
      this.medicalPractices = data;
    }
  }

  onToggleButton = val => {
    this.buttonSelected = val;
  };

  readMore = () => {
    this.page++;
    this.matchMedical();
  };

  readMoreDiscussion = () => {
    this.pageGroup++;
    this.matchDiscussionGroup();
  };

  linkToProfile(userId: number) {
    this.route.navigate([`admin/profile/${userId}`]);
  }

  onLinkGroup(groupId: number) {
    this.route.navigate([`/admin/groups/${this.currentAccount.id}`], { queryParams: { group: groupId } });
  }

  _hadlerSub(subs) {
    return subs.map(sub => sub.name).join(' ,');
  }
}
