import { Status } from 'app/shared/model/enumerations/status.model';

export interface ISpeciality {
  id?: number;
  name?: string;
  description?: string;
  status?: Status;
  subs?: ISpeciality[];
  userLogin?: string;
  userId?: number;
  primaryId?: number;
  primaryName?: string;
  createdBy?: string;
  sub?: ISpeciality[];
}

export class Speciality implements ISpeciality {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public status?: Status,
    public subs?: ISpeciality[],
    public userLogin?: string,
    public userId?: number,
    public createdBy?: string,
    public primaryId?: number,
    public primaryName?: string
  ) {
    this.id = id || null;
    this.name = name || null;
    this.primaryId = primaryId || null;
    this.primaryName = primaryName || null;
  }
}
