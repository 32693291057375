import { Moment } from 'moment';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IEndorsement {
  id?: number;
  name?: string;
  specialityId?: number;
  endorsedBy?: number;
  userId?: number;
  status?: Status;
  createdBy?: string;
  createdDate?: Moment;
  lastModifiedBy?: string;
  lastModifiedDate?: Moment;
}

export class Endorsement implements IEndorsement {
  constructor(
    public id?: number,
    public name?: string,
    public specialityId?: number,
    public endorsedBy?: number,
    public userId?: number,
    public status?: Status,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment
  ) {}
}
