import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IMessageGroup, MessageGroup } from 'app/shared/model/message-group.model';
import { IMessage, Message } from 'app/shared/model/message.model';
import { isEmpty } from 'app/shared';
import { MessageService } from 'app/admin/messages/message.service';

@Component({
  selector: 'gp-send-message-modal',
  templateUrl: './send-message-modal.component.html',
  styleUrls: ['./send-message-modal.component.scss']
})
export class SendMessageModalComponent implements OnInit {
  @Input()
  public userId: number;

  @Input()
  public receiverId: number;

  messageGroup: IMessageGroup;
  message: IMessage[];
  txtMessage: string;
  title: string;
  constructor(public activeModal: NgbActiveModal,
              private messageService: MessageService) {}

  ngOnInit() {
    this.messageGroup = new MessageGroup();
    this.message = [];
  }

  passBack() {
    this.activeModal.close();
  }

  async sendMessage() {
    if (isEmpty(this.txtMessage)) {
      return;
    }
    const message : IMessage = this.createMessage();
    if (this.messageGroup.id == null) {
      this.messageGroup.senderId = this.userId;
      this.messageGroup.receiverIds = [this.userId, this.receiverId].join(',');
      this.messageGroup.title = this.title;
      // do not send the message here, we created already a new message group
      // this.messageGroup.message = this.txtMessage;
      await this.messageService.newMessageGroup(this.messageGroup).toPromise().then(res => {
        this.messageGroup.id = res.id;
      });
    }
    message.groupId = this.messageGroup.id;
    this.messageService.sendMessage(message).subscribe(res => {
      this.loadMessageByGroupId();
      this.txtMessage = null;
      this.title = null;
    });

  }

  private loadMessageByGroupId() {
    this.messageService.findMessageByGroup({
      groupId: this.messageGroup.id
    }).subscribe(res => {
      this.message = res.body;
    })
  }

  private createMessage(): IMessage {
    const message: IMessage = new Message();
    message.title = this.title;
    message.message = this.txtMessage;
    message.senderId = this.userId;
    return message;
  }
  
  linkToProfile(userId) {
    // do something
  }
}
