import { Route } from '@angular/router';

import { MdcprtComponent } from './Mdcprt.component';

export const MdcprtRoute: Route = {
    path: 'mdcprt',
    component: MdcprtComponent,
    data: {
      pageTitle: 'Medical Practices Management'
    }
  };
