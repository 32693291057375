import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { IDiscussionGroup } from 'app/shared/model/discussion-group.model';
import { Observable } from 'rxjs';
import { createRequestOption } from 'app/shared';
import { isNullOrUndefined } from 'util';
import { IDiscussion } from 'app/shared/model/discussion.model';
import { IDiscussionLike } from 'app/shared/model/discussion-like.model';
import { IFeedBack } from 'app/shared/model/feed-back.model';
import { IDiscussions } from 'app/shared/model/discussions.model';
import { IGroupFollow } from 'app/shared/model/group-follow.model';

type DiscussionResponseType = HttpResponse<IDiscussions>;
type DiscussionGroupResponseType = HttpResponse<IDiscussionGroup[]>;
type EntityResponseType = HttpResponse<IDiscussionGroup>;

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  resourceUrl = SERVER_API_URL + 'api/discussions';
  resourceDiscussionGroupUrl = SERVER_API_URL + 'api/discussion-groups';
  resourceDiscussionLikeUrl = SERVER_API_URL + 'api/discussion-likes';
  resourceFeedbackUrl = SERVER_API_URL + 'api/feedback';
  resourceGroupFollowUrl = SERVER_API_URL + 'api/group-follows';
  constructor(private http: HttpClient) {}

  getDiscussionByGroup(req?: any): Observable<DiscussionResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IDiscussions>(`${this.resourceUrl}/byGroup`, { params: options, observe: 'response' });
  }

  getGroup(textSearch: string, req?: any): Observable<DiscussionGroupResponseType> {
    let options: HttpParams = createRequestOption(req);
    if (!isNullOrUndefined(textSearch) && textSearch !== '') {
      options = options.set('textSearch', textSearch);
    }
    return this.http.get<IDiscussionGroup[]>(`${this.resourceDiscussionGroupUrl}/publicGroup`, { params: options, observe: 'response' });
  }

  sendDiscussion(discussion: IDiscussion): Observable<any> {
    return this.http.post(`${this.resourceUrl}/sendGroup`, discussion);
  }

  createGroup(discussionGroup: IDiscussionGroup): Observable<any> {
    return this.http.post(`${this.resourceDiscussionGroupUrl}`, discussionGroup);
  }

  likeDiscussion(discussionLike: IDiscussionLike): Observable<any> {
    return this.http.put(`${this.resourceDiscussionLikeUrl}/group`, discussionLike);
  }

  reportPost(feedBack: IFeedBack): Observable<any> {
    return this.http.post(this.resourceFeedbackUrl, feedBack);
  }

  createGroupFollow(groupFollow: IGroupFollow) {
    return this.http.post(this.resourceGroupFollowUrl, groupFollow);
  }

  deleteGroupFollow(followId: number) {
    return this.http.delete(`${this.resourceGroupFollowUrl}/${followId}`);
  }

  updateGroup(discussionGroup: IDiscussionGroup): Observable<EntityResponseType> {
    return this.http.put<IDiscussionGroup>(this.resourceDiscussionGroupUrl, discussionGroup, { observe: 'response' });
  }
}
