import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IEndorsementUser } from 'app/shared/model/endorsement-user.model';
import { EndorsementsService } from './endorsements.service';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { IEndorsement, Endorsement } from 'app/shared/model/endorserment.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { ProfileService } from '../../profile.service';
import { isEmpty } from 'app/shared';

@Component({
  selector: 'gp-endorsements-modal',
  templateUrl: './endorsements-modal.component.html',
  styleUrls: ['./endorsements-modal.component.scss']
})
export class EndorsementsModalComponent implements OnInit {
  @Input() public endorSement: IEndorsementUser[];
  @Input() public endorUser: number;
  @Input() public userId: number;

  specialitySubs: string[];
  filteredResult: string[];
  textSpecility: string;
  arrSpecility: IEndorsement[];
  constructor(public activeModal: NgbActiveModal, private service: EndorsementsService, private profileService: ProfileService) {}

  ngOnInit() {
    this.filteredResult = [];
    this.specialitySubs = [];
    this.findAllSepecialitySubs();
    this.service
      .getEndorSerment(this.userId)
      .pipe(map((res: HttpResponse<IEndorsement[]>) => res.body))
      .subscribe(response => {
        this.arrSpecility = response;
        this.arrSpecility.map(item => {
          item.isChecked = item.endorsedBy === this.endorUser;
        })
      });
  }

  passBack() {
    this.activeModal.close(true);
  }

  selectSpeciality(name) {
    this.textSpecility = name;
    this.filteredResult = [];
  }

  addEndorSerment() {
    if (isNullOrUndefined(this.textSpecility) || this.textSpecility === '') {
      return;
    }
    if (this.arrSpecility.findIndex(item => item.name === this.textSpecility) >=0 ){
      return;
    }
    const endor: IEndorsement = new Endorsement();
    endor.name = this.textSpecility;
    endor.status = this.specialitySubs.includes(this.textSpecility) ? Status.APPROVED : Status.UNAPPROVED;
    endor.userId = this.userId;
    endor.endorsedBy = this.endorUser;
    this.textSpecility = null;
    this.arrSpecility.push(endor);
  }

  handleChecked(event: any, index: number) {
    if (event.target.checked) {
      this.arrSpecility[index].endorsedBy = this.endorUser;
      this.arrSpecility[index].isChecked = true;
    } else {
      this.arrSpecility[index].isChecked = false;
    }
  }

  async saveEndor() {
    const promies = this.arrSpecility.filter(item => (item.isChecked) || (!item.isChecked && item.id != null))
    .map(async item => {
      if (item.isChecked) {
        if (isNullOrUndefined(item.id)) {
          await this.service.createEndor(item).toPromise().then();
        } else {
          await this.service.updateEndor(item).toPromise().then();
        }
      } else {
        await this.service.deleteEndor(item.id).toPromise().then();
      }
    })
    await Promise.all(promies);
    this.activeModal.close(true);
  }

  findAllSepecialitySubs() {
    this.profileService.findAllSpecialitySub().subscribe(res => {
      this.specialitySubs = res;
    });
  }

  filterSpecialitySub() {
    if (!isEmpty(this.textSpecility)) {
      const _term = this.textSpecility.toLowerCase();
      this.filteredResult = this.specialitySubs.filter((el: any) => {
        return el.toLowerCase().indexOf(_term.toLowerCase()) > -1;
      });
    } else {
      this.filteredResult = [];
    }
  }
}
