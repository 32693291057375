import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'gp-shortname-modal',
  templateUrl: './shortname-modal.component.html',
  styles: []
})
export class ShortnameModalComponent implements OnInit {
  @Input() public inputModel;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  passBack() {
    this.activeModal.close(this.inputModel);
  }
}
