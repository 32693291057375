import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';

import { gpplcrState } from './';
import { HelpComponent } from './help/help.component';
import { ViewpostComponent } from './post/viewpost';
import { ViewpageComponent } from './post/viewpage';
import { GpplcrSharedModule } from 'app/shared';

@NgModule({
  imports: [CommonModule, GpplcrSharedModule, ReactiveFormsModule, RouterModule.forChild(gpplcrState)],
  declarations: [HelpComponent, ViewpostComponent, ViewpageComponent],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GpplcrModule {}
