export interface IUser {
  id?: number;
  login?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  imageUrl?: string;
  fullName?: string;
  profileView?: number;
  numberOfEndorsement?: number;
  likePublicPost?: number;
}

export class User implements IUser {
  constructor(
    public id?: number,
    public login?: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public imageUrl?: string,
    public fullName?: string
  ) {
    this.id = id || null;
    this.login = login || null;
    this.firstName = firstName || null;
    this.lastName = lastName || null;
    this.email = email || null;
    this.imageUrl = imageUrl || null;
    this.fullName = fullName || null;
  }
}
