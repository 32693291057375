import { Routes } from '@angular/router';

import { GroupsComponent } from './groups.component';

export const groupsRoute: Routes = [
  {
    path: 'groups',
    component: GroupsComponent,
    data: {
      pageTitle: 'Discussion Topics'
    }
  },
  {
    path: 'groups/:id',
    component: GroupsComponent,
    data: {
      pageTitle: 'Discussion topic'
    },
  }
];
