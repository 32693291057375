import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IMedicalPractice } from 'app/shared/model/medical-practice.model';

@Component({
  selector: 'gp-medical-practice',
  templateUrl: './medical-practice.component.html',
  styles: []
})
export class MedicalPracticeComponent implements OnInit {
  @Input()
  medicalPractices: IMedicalPractice[];

  @Input()
  isView: boolean;

  @Output()
  add: EventEmitter<any> = new EventEmitter();

  @Output()
  edit: EventEmitter<any> = new EventEmitter();

  @Output()
  delete: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  addMedicalPractice() {
    this.add.emit();
  }

  editMedicalPractice(medicalPracticeModel: any) {
    this.edit.emit(medicalPracticeModel);
  }

  deleteMedicalPractice(medicalPracticeModel: any) {
    this.delete.emit(medicalPracticeModel);
  }

  getAddressString(address: IMedicalPractice) {
    const unitStr = address.unit ? `Unit ${address.unit},` : '';
    const numberString = address.number ? `No ${address.number},` : '';
    const streetString = address.street ? `${address.street},` : '';
    const stateString = address.state ? `${address.state},` : '';
    const cityString = address.city ? `${address.city},` : '';
    const countryString = address.country ? `${address.country}` : '';
    return `${numberString} ${unitStr} ${streetString} ${stateString} ${cityString} ${countryString}`;
  }

  translateUsualAvailability(usualAvailability: number) {
    if (usualAvailability === 1) {
      return '24 hours';
    } else if (usualAvailability === 2) {
      return '48 hours';
    } else if (usualAvailability === 3) {
      return '72 hours';
    } else if (usualAvailability === 7) {
      return '1 week';
    }
    return 'Undefined';
  }

  formatTime(decimalTimeString: string) {
    const hoursMinute = decimalTimeString.split(':');
    let hours : any = hoursMinute[0];
    let minutes: any = hoursMinute[1];
    if (+hoursMinute[0] < 10) {
      hours = "0" + hoursMinute[0];
    }

    if (+hoursMinute[1] < 10) {
      minutes = "0" + hoursMinute[1];
    }
    return hours + ":" + minutes
  }

  getTimeRange(from: string, to: string) {
    if (from && to) {
      return `${this.formatTime(from)} - ${this.formatTime(to)}`;
    }

    if (from) {
      return `From ${this.formatTime(from)}`;
    }

    if (to) {
      return `To ${this.formatTime(to)}`;
    }

    return 'Closed';
  }
}
