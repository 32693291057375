import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IEndorsement } from 'app/shared/model/endorserment.model';
import { createRequestOption } from 'app/shared';
import { SERVER_API_URL } from 'app/app.constants';

type EntityEndorsement = HttpResponse<IEndorsement[]>;
@Injectable({
  providedIn: 'root'
})
export class EndorsementsService {
  private resourceUrl = SERVER_API_URL + 'api/endorsements';
  constructor(private http: HttpClient) {}

  getEndorSerment(userId: number): Observable<EntityEndorsement> {
    return this.http.get<IEndorsement[]>(`${this.resourceUrl}/${userId}/user`, { observe: 'response' });
  }

  getEndorSementByUser(req?: any): Observable<EntityEndorsement> {
    const options = createRequestOption(req);
    return this.http.get<IEndorsement[]>(`${this.resourceUrl}/profile`, { params: options, observe: 'response' });
  }

  createEndor(endor: IEndorsement): Observable<any> {
    return this.http.post(this.resourceUrl, endor);
  }

  updateEndor(endor: IEndorsement): Observable<any> {
    return this.http.put(this.resourceUrl, endor);
  }

  deleteEndor(id: number): Observable<any> {
    return this.http.delete(`${this.resourceUrl}/${id}`);
  }
}
