import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfileModalService } from './profile-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, AccountService, User } from 'app/core';
import { filter, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ProfileService } from './profile.service';
import { UserProfile } from 'app/shared/model/user-profile.model';
import { Observable, forkJoin } from 'rxjs';
import { JhiAlertService } from 'ng-jhipster';
import { IMedicalPractice, MedicalPractice } from 'app/shared/model/medical-practice.model';
import { ISpeciality } from 'app/shared/model/speciality.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { splitStr } from 'app/shared';
import { isNullOrUndefined } from 'util';
import { IFavourities, Favourities } from 'app/shared/model/favourities.model';
import { IDiscussionGroup } from 'app/shared/model/discussion-group.model';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { GroupsModalService } from '../groups/groups-modal.service';
import { GroupsService } from '../groups/groups.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../confirm/delete-modal/delete-modal.component';
import { EndorsementsService } from './modal/endorsements-modal/endorsements.service';

@Component({
  selector: 'gp-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  userProfile: UserProfile;
  currentAccount: Account;
  userId: number;
  isView: boolean;
  favourites: IFavourities;
  isFavourities: boolean;
  pageActivity: number;
  options: any;
  active: Status.ACTIVE;
  shortname: string;

  @ViewChild(ContextMenuComponent, { static: true }) public contextMenu: ContextMenuComponent;

  constructor(
    private profileModalService: ProfileModalService,
    private jhiAlertService: JhiAlertService,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private profileService: ProfileService,
    private router: Router,
    private ngContextService: ContextMenuService,
    private groupModelService: GroupsModalService,
    private groupService: GroupsService,
    private modalService: NgbModal,
    private endorService: EndorsementsService
  ) {}

  ngOnInit() {
    this.pageActivity = 0;
    this.favourites = null;
    this.isFavourities = false;
    this.userProfile = new UserProfile();
    this.accountService.changeUsername(localStorage.getItem('loggedinUsername'));
    this.accountService.identity().then(account => {
      this.currentAccount = account;
      this.userId = account.id;
      this.isView = false;
      this.shortname = this.activatedRoute.snapshot.paramMap.get('shortname');
      if (!isNullOrUndefined(this.shortname)) {
        this.profileService
          .findByShortname(this.shortname)
          .subscribe(res => {
            this.userProfile = res;
            this.userId = this.userProfile.userId;
          });
      } else if (!isNullOrUndefined(this.activatedRoute.snapshot.paramMap.get('id'))) {
        this.userId = +this.activatedRoute.snapshot.paramMap.get('id');
      }
      
      this.isView = this.userId === account.id ? false : true;
      if (this.userId !== this.currentAccount.id) {
        this.getFavories();
        this.updateProfileView();
      }
      this.options = {
        user: this.userId,
        page: 0,
        size: 10,
        sort: ['createdDate,desc']
      };
      this.loadCurrentUserProfile(this.userId);
    });
  }

  updateProfileView() {
    this.profileService.updateProfileView(this.userId).subscribe();
  }

  loadCurrentUserProfile(userId: number) {
    forkJoin([
      this.profileService.findByUser(userId),
      this.profileService.getMedicalPractices(this.options),
      this.profileService.getSpecialities({
        status: `${Status.APPROVED},${Status.UNAPPROVED}`,
        user: this.userId,
        page: 0,
        size: 100,
        sort: ['primary,desc']
      }),
      this.profileService.getDiscussionGroupByFollow(this.options),
      this.profileService.findActivity(this.options),
      this.endorService.getEndorSementByUser({ user: this.userId })
    ]).subscribe(([res1, res2, res3, res4, res5, res6]) => {
      this.userProfile = res1.body;
      this.userProfile.medicalPractices = res2.body;
      this.userProfile.specialities = res3.body;
      this.userProfile.discussionGroups = res4.body;
      this.userProfile.discussions = res5.body;
      this.userProfile.endorserment = res6.body;
    });
  }

  getAcitivity() {
    const options = {
      user: this.userId,
      page: this.pageActivity,
      size: 10,
      sort: ['createdDate,desc']
    };
    this.profileService
      .findActivity(options)
      .pipe(map((res: HttpResponse<IDiscussionGroup[]>) => res.body))
      .subscribe(res => {
        this.userProfile.discussions = this.userProfile.discussions.concat(res);
      });
  }

  getFavories() {
    this.profileService
      .getFavorities({
        userId: this.currentAccount.id,
        profileId: this.userId
      })
      .pipe(map((res: HttpResponse<IFavourities>) => res.body))
      .subscribe(res => {
        this.favourites = res;
        if (!isNullOrUndefined(this.favourites)) {
          this.isFavourities = true;
        }
      });
  }

  getSpeciality() {
    this.profileService
      .getSpecialities({
        status: `${Status.APPROVED},${Status.UNAPPROVED}`,
        user: this.userId,
        page: 0,
        size: 100,
        sort: ['primary,desc']
      })
      .subscribe(res => {
        this.userProfile.specialities = res.body;
      });
  }

  async hadleFavorites() {
    if (this.isFavourities) {
      await this.profileService
        .deleteFavorities(this.favourites.id)
        .toPromise()
        .then(res => {
          this.favourites = null;
          this.isFavourities = false;
        });
    } else {
      const favories: IFavourities = new Favourities();
      favories.userId = this.currentAccount.id;
      favories.profileId = this.userId;
      await this.profileService
        .createFavorities(favories)
        .toPromise()
        .then(item => {
          this.favourites = item;
          this.isFavourities = true;
        });
    }
  }

  // ******************************** Name *************************************
  editName() {
    this.profileModalService.openName(this.userProfile, this.handleUpdateName);
  }

  handleUpdateName = (result: any) => {
    this.userProfile.firstName = result.firstName;
    this.userProfile.lastName = result.lastName;
    this.userProfile.imageUrl = result.imageUrl;
    this.accountService.changeUsername(result.firstName + " " + result.lastName);
    this.patchingUserProfileInfo();
  };

  // ******************************** AHPRA Number *************************************
  editAhpraNumber() {
    this.profileModalService.openAhpraNumber(this.userProfile.ahpraNumber, this.handleUpdateAhpraNumber);
  }

  handleUpdateAhpraNumber = (result: any) => {
    this.userProfile.ahpraNumber = result;

    this.patchingUserProfileInfo();
  };

  // ******************************** About *************************************
  editAbout() {
    this.profileModalService.openAbout(this.userProfile.about, this.handleUpdateAbout);
  }

  handleUpdateAbout = (result: String) => {
    this.userProfile.about = result;

    this.patchingUserProfileInfo();
  };

  handleCreateSpeciality = (result: ISpeciality) => {
    const specialities: ISpeciality = result;
    specialities.status = specialities.id === null ? Status.UNAPPROVED : Status.APPROVED;
    specialities.userId = this.currentAccount.id;
    specialities.userLogin = this.currentAccount.login;
    this.profileService.createSpecialities(specialities).subscribe(
      res => {
        this.onSaveSuccess();
        this.getSpeciality();
      },
      () => this.onSaveError()
    );
  };

  patchingUserProfileInfo() {
    const patchingProfile = new UserProfile();
    patchingProfile.about = this.userProfile.about;
    patchingProfile.firstName = this.userProfile.firstName;
    patchingProfile.lastName = this.userProfile.lastName;
    patchingProfile.ahpraNumber = this.userProfile.ahpraNumber;
    patchingProfile.imageUrl = this.userProfile.imageUrl;
    this.subscribeToSaveResponse(this.profileService.patch(this.currentAccount.id, this.userProfile));
  }

  editEndorsement() {
    this.profileModalService.openEndorsements(
      this.currentAccount.id,
      this.userId,
      this.userProfile.endorserment,
      this.handleUpdateEndorsement
    );
  }

  handleUpdateEndorsement = res => {
    if (!isNullOrUndefined(res)) {
      if (res) {
        this.loadCurrentUserProfile(this.userId);
      }
    }
  };

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>) {
    result.subscribe(() => this.onSaveSuccess(), () => this.onSaveError());
  }

  protected onSaveSuccess() {
    this.onSuccess('Save successfully!');
  }

  protected onSaveError() {
    this.onError('Error on saving profile!');
  }

  protected onError(errorMessage: string) {
    this.jhiAlertService.error(errorMessage, null, null);
  }

  protected onSuccess(successMessage: string) {
    this.jhiAlertService.success(successMessage, null, null);
  }

  // ******************************** Medical Practice *************************************
  editMedicalPractice(medicalPractice: MedicalPractice) {
    this.profileModalService.openMedicalPractice(medicalPractice, this.handleUpdateMedicalPractice);
  }

  addMedicalPractice() {
    const medicalPractice = new MedicalPractice();
    medicalPractice.userId = this.currentAccount.id;
    medicalPractice.userLogin = this.currentAccount.login;
    this.profileModalService.openMedicalPractice(medicalPractice, this.handleAddMedicalPractice);
  }

  deleteMedicalPractice = (result: MedicalPractice) => {
    this.profileService.deleteMedicalPractice(result.id).subscribe(
      () => {
        const nextMedicalPractice = this.userProfile.medicalPractices.filter(obj => obj.id !== result.id);
        this.userProfile.medicalPractices = nextMedicalPractice;
      },
      (res: HttpErrorResponse) => this.onError(res.message)
    );
  };

  handleAddMedicalPractice = (result: MedicalPractice) => {
    this.profileService
      .createMedicalPractice(result)
      .pipe(
        filter((mayBeOk: HttpResponse<IMedicalPractice>) => mayBeOk.ok),
        map((response: HttpResponse<IMedicalPractice>) => response.body)
      )
      .subscribe(
        (res: IMedicalPractice) => this.loadCurrentUserProfile(this.userId),
        (error: HttpErrorResponse) => this.onError(error.message)
      );
  };

  handleUpdateMedicalPractice = (result: MedicalPractice) => {
    this.profileService
      .updateMedicalPractice(result)
      .pipe(
        filter((mayBeOk: HttpResponse<IMedicalPractice>) => mayBeOk.ok),
        map((response: HttpResponse<IMedicalPractice>) => response.body)
      )
      .subscribe(
        (res: IMedicalPractice) => {
          this.loadCurrentUserProfile(this.userId);
        },
        (error: HttpErrorResponse) => this.onError(error.message)
      );
  };

  // ************************************* Speciality *************************************
  editSpeciality() {
    this.profileModalService.openSpeciality(this.currentAccount.login, this.handleCreateSpeciality);
  }

  handleDeleteSpeciality = (id: number) => {
    this.modalService.open(DeleteModalComponent, { size: 'sm', backdrop: 'static' }).result.then(res => {
      if (res === true) {
        // const nextSpeciality = this.userProfile.specialities.filter(obj => obj.id !== id);
        this.profileService
          .deleteSpeciality(id, {
            userId: this.currentAccount.id
          })
          .subscribe(
            () => {
              this.onSaveSuccess();
              this.getSpeciality();
            },
            () => this.onSaveError()
          );
      }
    });
  };

  sendMessage() {
    this.profileModalService.openSendMessage(this.userId, this.currentAccount.id, this.handleSendMessage);
  }

  onLinkGroup(groupId: number) {
    this.router.navigate([`/admin/groups/${this.userId}`], { queryParams: { group: groupId } });
  }

  handleSendMessage = res => {};
  splitStr(name: string, length: number) {
    splitStr(name, length);
  }

  onScroll() {
    this.pageActivity++;
    this.getAcitivity();
  }

  onRightClick($event: KeyboardEvent) {
    if (this.userId === this.currentAccount.id) {
      return;
    }
    this.ngContextService.show.next({
      contextMenu: this.contextMenu,
      event: $event,
      item: this.userId
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  reportPost() {
    if (this.currentAccount.id === this.userProfile.id) {
      return;
    }
    const user: User = new User();
    user.email = this.userProfile.email;
    user.id = this.userProfile.id;
    user.firstName = this.userProfile.firstName;
    user.lastName = this.userProfile.lastName;
    this.groupModelService.reportPost(this.currentAccount.id, user, this.reportPostUser);
  }

  reportPostUser = result => {
    this.groupService.reportPost(result).subscribe(res => {
      alert('success');
    });
  };

  showApprove(specialityItem: ISpeciality) {
    if (specialityItem.status === Status.APPROVED) {
      return true;
    }
    return this.showUnApprove(specialityItem);
  }

  showUnApprove(specialityItem: ISpeciality) {
    if (
      specialityItem.status === Status.UNAPPROVED &&
      (specialityItem.createdBy === this.currentAccount.login || specialityItem.userId === this.currentAccount.id)
    ) {
      return true;
    }
    return false;
  }

  copyAhphaNo() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.userProfile.ahpraNumber;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    window.open('https://www.ahpra.gov.au/Registration/Registers-of-Practitioners.aspx', "_blank");
  }
  
  // ****** Shortname section *******
  gotoUser(userId: number) {
    const userUrl = '/admin/profile/' + userId;
    this.router.navigate([userUrl]);
  }
  
  editShortname() {
    this.profileModalService.openShortname(this.userProfile.shortname, this.handleUpdateShortname);
  }
  
  handleUpdateShortname = (result: string) => {
    this.patchingUserShortname(result);
  };
  
  patchingUserShortname(result: string) {
    // this.subscribeToSaveResponse(this.profileService.setShortname(this.userId, this.shortname));
    this.profileService.setShortname(this.userId, result).subscribe(
        (res) => {
          this.onSaveSuccess();
          this.userProfile.shortname = result;
          this.shortname = result;
          // alert("Short name updated!");
        }, 
        (err: HttpErrorResponse) => alert("Given short name already taken!")
    );
  }
  
  // ********** User menu actions ************
  suspendUser() {
    if (confirm("Do you want suspend this user?")) {
      this.profileService.suspendUser(this.userProfile.userId).subscribe(res => {
        this.onSaveSuccess();
      })
    }
  }
}
