import { Component, OnInit, OnDestroy } from '@angular/core';

import { Account, AccountService, LoginService, RegisterTypeModalService } from 'app/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'app/admin/messages/message.service';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SearchResultDashboardService } from 'app/admin/search-result-dashboard/search-result-dashboard.service';

@Component({
  selector: 'gp-navbar-main',
  templateUrl: './navbar-main.component.html',
  styleUrls: ['navbar.scss']
})
export class NavbarMainComponent implements OnInit, OnDestroy {
  pageTitle: String;
  account: Account;
  isNavbarCollapsed: boolean;
  modalRef: NgbModalRef;
  username: string;
  unreadMessages = 0;
  currAccountId: number;
  subscription: Subscription;
  notificationInterval = 9999;

  constructor(
    protected loginService: LoginService,
    protected accountService: AccountService,
    protected profileService: ProfileService,
    protected registerTypeModalService: RegisterTypeModalService,
    protected messageService: MessageService,
    protected router: Router,
    protected searchDashboardService: SearchResultDashboardService
  ) {
    this.isNavbarCollapsed = true;
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.username = localStorage.getItem('loggedinUsername');
        this.pageTitle = this.getPageTitle(this.router.routerState.snapshot.root);
      }
    });
    this.accountService.username.subscribe(result => {
      this.username = result; // this set's the username to the default observable value
    });
    this.accountService.getAuthenticationState().subscribe(account => {
      if (account) {
        this.account = account;
        this.currAccountId = account.id;
        // this.username = account.firstName + ' ' + account.lastName;
        // this.accountService.changeUsername(this.username);
        this.messageService.countUnreadMessagesByUser(this.account.id).subscribe(res => {
          this.unreadMessages = res;
        });
        this.subscription = timer(0, this.notificationInterval)
          .pipe(switchMap(res => this.messageService.countUnreadMessagesByUser(this.currAccountId)))
          .subscribe(res => (this.unreadMessages = res));
      }
    });
  }

  isAuthenticated() {
    return this.accountService.isAuthenticated();
  }

  toggleSideBar() {
    document.body.classList.toggle('expand-menu');
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  collapseNavbar() {
    this.isNavbarCollapsed = true;
  }

  selectRegisterType() {
    this.isNavbarCollapsed = true;
    this.modalRef = this.registerTypeModalService.open();
  }

  logout() {
    this.collapseNavbar();
    this.loginService.logout();
    this.router.navigate(['/']);
  }

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
    let title: string = routeSnapshot.data && routeSnapshot.data['pageTitle'] ? routeSnapshot.data['pageTitle'] : 'gpplcrApp';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  gotoHelpPage() {
    const helplink = this.router.routerState.snapshot.url.split('/').join('-');
    // eslint-disable-next-line no-console
    console.log(helplink);
    // this.router.navigate(['/viewpage/about' + helplink]);
    window.open('https://healthpreferral.com.au/help/about' + helplink, '_blank');
  }
}
