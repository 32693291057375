import { Routes } from '@angular/router';

import { PostsComponent } from './posts.component';

export const postsRoute: Routes = [
  {
    path: 'posts',
    component: PostsComponent,
    data: {
      pageTitle: 'posts'
    },
  },
  {
    path: 'posts/:id',
    component: PostsComponent,
    data: {
      pageTitle: 'posts'
    },
  }
];
