import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InvitationService } from './invitation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EMAIL_ALREADY_USED_TYPE } from 'app/shared';
import { AccountService } from 'app/core';

@Component({
  selector: 'gp-invitation',
  templateUrl: './invitation.component.html'
})
export class InvitationComponent {
  error: string;
  errorEmailNotExists: string;
  success: boolean;

  invitationForm = this.fb.group({
    firstName: [undefined, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
    lastName: [undefined, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
    email: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(254), Validators.email]],
    phone: [undefined],
    ahpraNumber: [''],
    type: ['GP', [Validators.required]],
    status: ['SENDING', [Validators.required]]
  });

  constructor(
      private fb: FormBuilder,
      private accountService: AccountService,
      private invitationService: InvitationService
  ) {
    this.accountService.changeUsername(localStorage.getItem('loggedinUsername'));
  }

  sendInvitationEmail() {
    const email = this.invitationForm.get('email').value;
    const lastName = this.invitationForm.get('lastName').value;
    const firstName = this.invitationForm.get('firstName').value;
    const phone = this.invitationForm.get('phone').value;
    const ahpraNumber = this.invitationForm.get('ahpraNumber').value;
    const type = this.invitationForm.get('type').value;
    const status = this.invitationForm.get('status').value;

    let invitation = {};

    invitation = { ...invitation, email, lastName, firstName, phone, ahpraNumber, type, status };
    this.errorEmailNotExists = null;

    this.invitationService.sendInvitation(invitation).subscribe(
      () => {
        this.success = true;
      },
      response => this.processError(response)
    );
  }

  private processError(response: HttpErrorResponse) {
    this.success = null;
    if (response.status === 400 && response.error.type === EMAIL_ALREADY_USED_TYPE) {
      this.errorEmailNotExists = 'ERROR';
    } else {
      this.error = 'ERROR';
    }
  }
}
