import { Component, OnInit, OnDestroy } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';
import { IPosts } from 'app/shared/model/posts.model';

@Component({
  selector: 'gp-viewpost',
  template: `<div [innerHTML]="content | safeHtml"></div>`
})
export class ViewpostComponent implements OnInit, OnDestroy {
  
  content = '';
  
  constructor(
      private http: HttpClient, 
      private route: ActivatedRoute
  ) { }
  
  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('landing-page');
  }
  
  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('landing-page');
    const id = this.route.snapshot.paramMap.get('id');
    const requrl = SERVER_API_URL + 'api/front/viewpost/' + id;
    this.http.get<IPosts>(requrl, { observe: 'response' }).subscribe(
        (res: HttpResponse<IPosts>) => (this.content = res.body.postContent),
        (err) => (this.content = "Post not found!")
    );
  }
  
}

export const viewpostRoute: Route = {
  path: 'viewpost/:id',
  component: ViewpostComponent,
  data: {
    pageTitle: 'View post'
  }
};