import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { IMedicalPractice } from 'app/shared/model/medical-practice.model';

type EntityResponseType = HttpResponse<IMedicalPractice>;
type EntityArrayResponseType = HttpResponse<IMedicalPractice[]>;

@Injectable({ providedIn: 'root' })
export class MdcprtService {
  public resourceUrl = SERVER_API_URL + 'api/medical-practices';

  constructor(protected http: HttpClient) {}

  create(item: IMedicalPractice): Observable<EntityResponseType> {
    return this.http.post<IMedicalPractice>(this.resourceUrl, item, { observe: 'response' });
  }

  update(item: IMedicalPractice): Observable<EntityResponseType> {
    return this.http.put<IMedicalPractice>(this.resourceUrl, item, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IMedicalPractice>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IMedicalPractice[]>(`${this.resourceUrl}`, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }
  
  findSimilar(item: IMedicalPractice): Observable<EntityArrayResponseType> {
    const reqUrl = SERVER_API_URL + 'api/front/sr/mpSimilar'
    return this.http.post<any>(reqUrl, item, { observe: 'response' });
  }
}
