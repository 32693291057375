import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { filter, map } from 'rxjs/operators';
import { JhiEventManager, JhiParseLinks, JhiDataUtils } from 'ng-jhipster';
import { Observable } from 'rxjs';

import { IMedicalPractice, MedicalPractice } from 'app/shared/model/medical-practice.model';
import { AccountService } from 'app/core/auth/account.service';

import { ITEMS_PER_PAGE } from 'app/shared/constants/pagination.constants';
import { MdcprtService } from './Mdcprt.service';

@Component({
  selector: 'Mdcprt',
  templateUrl: './Mdcprt.html'
})
export class MdcprtComponent implements OnInit, OnDestroy {
  
  public filterString: string;

  currentAccount: any;
  myItems: IMedicalPractice[];
  error: any;
  success: any;
  eventSubscriber: Subscription;
  routeData: any;
  links: any;
  totalItems: any;
  itemsPerPage: any;
  page: any;
  previousPage: any;
  reverse: any;
  currentItem: IMedicalPractice;

  isSaving: boolean;
  isCreating: boolean;

  constructor(
      protected mdcprtService: MdcprtService,
      protected parseLinks: JhiParseLinks,
      protected accountService: AccountService,
      protected activatedRoute: ActivatedRoute,
      protected dataUtils: JhiDataUtils,
      protected router: Router,
      protected eventManager: JhiEventManager
    ) {
      this.itemsPerPage = ITEMS_PER_PAGE;
      this.routeData = this.activatedRoute.data.subscribe(data => {
        if (data.pagingParams) {
          this.page = data.pagingParams.page;
          this.previousPage = data.pagingParams.page;
          this.reverse = data.pagingParams.ascending;
          // this.predicate = data.pagingParams.predicate;
        }
      });
    }

    loadAll() {
      if (!this.page) {
        this.page = 1;
      }
      this.mdcprtService
        .query({
          page: this.page - 1,
          size: this.itemsPerPage
          // sort: this.sort()
        })
        .subscribe((res: HttpResponse<IMedicalPractice[]>) => this.paginateMdcprt(res.body, res.headers));
    }

    loadPage(page: number) {
      if (page !== this.previousPage) {
        this.previousPage = page;
        this.transition();
      }
    }

    transition() {
      this.mdcprtService
      .query({
        page: this.page - 1,
        size: this.itemsPerPage
      })
      .subscribe((res: HttpResponse<IMedicalPractice[]>) => this.paginateMdcprt(res.body, res.headers));

    }

    clear() {
      this.page = 1;
      this.mdcprtService
      .query({
        page: this.page - 1,
      })
      .subscribe((res: HttpResponse<IMedicalPractice[]>) => this.paginateMdcprt(res.body, res.headers));

    }

    ngOnInit() {
      this.isSaving = false;
      this.isCreating = false;
      this.currentItem = new MedicalPractice();
      this.loadAll();
      this.accountService.identity().then(account => {
        this.currentAccount = account;
      });
      this.registerChangeInMdcprt();
    }

    ngOnDestroy() {
      this.eventManager.destroy(this.eventSubscriber);
    }

    trackId(index: number, item: IMedicalPractice) {
      return item.id;
    }

    byteSize(field) {
      return this.dataUtils.byteSize(field);
    }

    openFile(contentType, field) {
      return this.dataUtils.openFile(contentType, field);
    }

    registerChangeInMdcprt() {
      this.eventSubscriber = this.eventManager.subscribe('medicalPracticeListModification', response => this.loadAll());
    }

    protected paginateMdcprt(data: IMedicalPractice[], headers: HttpHeaders) {
      this.links = this.parseLinks.parse(headers.get('link'));
      this.totalItems = parseInt(headers.get('X-Total-Count'), 10);
      this.myItems = data;
    }
    
    clearForm() {
      this.currentItem = new MedicalPractice();
    }
    
    validateEditForm() {
      if(confirm("Are you sure to apply changes?")) {
        this.save();
      }
    }
    
    findSimilar() {
      this.mdcprtService.findSimilar(this.currentItem).subscribe(
        (data: any) => {
          // eslint-disable-next-line  no-console
          console.log(data);
          this.myItems = data.body;
          this.currentItem = new MedicalPractice();
        }, 
        (err) => alert(err.message)
      );
    }
    
    save() {
      this.isSaving = true;
      if (this.currentItem.id) {
        this.subscribeToSaveResponse(this.mdcprtService.update(this.currentItem));
      } else {
        this.isCreating = true;
        this.subscribeToSaveResponse(this.mdcprtService.create(this.currentItem));
      }
    }

    protected subscribeToSaveResponse(result: Observable<HttpResponse<IMedicalPractice>>) {
      result.subscribe((data: any) => {
        this.onSaveSuccess();
        if (this.isCreating) {
          this.loadAll();
          this.currentItem = new MedicalPractice();
          this.isCreating = false;
        }
      }, () => this.onSaveError());
    }

    protected onSaveSuccess() {
      this.isSaving = false;
    }

    protected onSaveError() {
      this.isSaving = false;
    }
    
    deleteRow(medicalPractice: IMedicalPractice) {
      if(confirm("Are you sure you want to delete item ID " + medicalPractice.id + "?")) {
        this.mdcprtService.delete(medicalPractice.id).subscribe(response => {
          this.loadAll();
        });
      }
    }
    
    scrollToTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

}
