import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { ProfileService } from '../../profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';

@Component({
  selector: 'gp-name-modal',
  templateUrl: './name-modal.component.html',
  styles: []
})
export class NameModalComponent implements OnInit {
  @Input() public inputModel;
  
  previewUrl: any;
  customFile: File
  
  editForm = this.fb.group({
    firstName: [],
    lastName: [],
    imageUrl: [],
  });

  constructor(public activeModal: NgbActiveModal, 
      private profileService: ProfileService,
      private httpClient: HttpClient,
      private fb: FormBuilder) {}

  ngOnInit() {
    this.updateForm(this.inputModel);
    this.previewUrl = this.inputModel.imageUrl;
  }

  save() {
    const _userModel = this.createFromForm();
    this.activeModal.close(_userModel);
  }

  updateForm(userModel) {
    this.editForm.patchValue({
      firstName: userModel.firstName,
      lastName: userModel.lastName,
      imageUrl: userModel.imageUrl
    });
  }

  private createFromForm() {
    const entity = {
      firstName: this.editForm.get(['firstName']).value,
      lastName: this.editForm.get(['lastName']).value,
      imageUrl: this.editForm.get(['imageUrl']).value,
    };
    return entity;
  }
  
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.customFile = file;
      
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.previewUrl = reader.result;
      }
      
    }
  }
  
  uploadProfileImage() {
    const formData = new FormData();
    formData.append('customFile', this.customFile);
    const postUrl = SERVER_API_URL + 'api/front/profiles/image/' + this.inputModel.id;
    this.httpClient.post<any>(postUrl, formData).subscribe(
        (res) => {
          this.editForm.get('imageUrl').setValue(res.fileDownloadUri);
          // alert('Upload successful!');
        },
        (res: HttpErrorResponse) => alert(res.message)
      );
  }
}
