import { Route } from '@angular/router';

import { SpcltComponent } from './Spclt.component';

export const SpcltRoute: Route = {
    path: 'spclt',
    component: SpcltComponent,
    data: {
      pageTitle: 'Specialities Management'
    }
  };
