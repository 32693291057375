import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewMessageModalComponent } from './modal/new-message-modal/new-message-modal.component';
import { AccountService, Account } from 'app/core';
import { MessageService } from './message.service';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { IMessageGroup, IUserBasic } from 'app/shared/model/message-group.model';
import { IMessage, Message } from 'app/shared/model/message.model';
import { isEmpty } from 'app/shared';
import { Router, ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ReceiverModalComponent } from './modal/receiver-modal/receiver-modal.component';
import { MessageReadModalComponent } from './modal/message-read-modal/message-read-modal.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'gp-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['messages.component.scss']
})
export class MessagesComponent implements OnInit {
  currentAccount: Account;
  textSearch: string;
  countGroup: number;
  countMessage: number;
  messageGroups: IMessageGroup[];
  messages: IMessage[];
  title: string;
  description: string;
  groupId: number;
  contentMessage: string;
  messageGroupId: number;

  currMessageGroup: IMessageGroup;

  public editorConfig = {
    placeholder: 'type the message ...',
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo']
  };

  public Editor = ClassicEditor;

  constructor(
    private modalService: NgbModal,
    private accountService: AccountService,
    private messageService: MessageService,
    private route: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.groupId = null;
    this.countGroup = 0;
    this.countMessage = 0;
    this.contentMessage = '';
    this.messageGroups = [];
    this.messages = [];
    this.textSearch = null;
    this.messageGroupId = null;
    if (!isNullOrUndefined(this.activatedRoute.snapshot.paramMap.get('id'))) {
      this.messageGroupId = +this.activatedRoute.snapshot.paramMap.get('id');
    }
    this.accountService.identity().then(account => {
      this.currentAccount = account;
      this.findAllGroup(false);
    });
    this.accountService.changeUsername(localStorage.getItem('loggedinUsername'));
  }

  findAllGroup(isLoader: boolean) {
    if (!isLoader) {
      this.countGroup = 0;
      this.messageGroups = [];
    }
    this.messageService
      .findGroupMessageHaveMe(this.textSearch, {
        user: this.currentAccount.id,
        page: this.countGroup,
        size: 10,
        sort: ['createdDate,desc']
      })
      .pipe(map((res: HttpResponse<IMessageGroup[]>) => res.body))
      .subscribe(res => {
        this.messageGroups = this.messageGroups.concat(res);
        this.textSearch = null;
        if (this.messageGroups.length > 0) {
          if (!isLoader && !isNullOrUndefined(this.messageGroupId)) {
            this.messageGroups[0].id = this.messageGroupId;
          }
          // this.onClickMessageByGroup(this.messageGroups[0].id, this.messageGroups[0].title, this.messageGroups[0].message, false);
          this.onClickMessageGroup(this.messageGroups[0]);
        }
      });
  }

  onClickMessageGroup(messageGroup: IMessageGroup) {
    this.currMessageGroup = messageGroup;
    this.onClickMessageByGroup(this.currMessageGroup.id, this.currMessageGroup.title, this.currMessageGroup.message, false);
  }

  onClickMessageByGroup(id: number, title: string, description: string, loadMore: boolean) {
    this.groupId = id;
    this.title = title;
    this.description = description;
    if (!loadMore) {
      this.countMessage = 0;
      this.messages = [];
    }
    this.messageService
      .findMessageByGroup({
        groupId: id,
        page: this.countMessage,
        size: 10,
        sort: ['createdDate,desc']
      })
      .pipe(map((res: HttpResponse<IMessage[]>) => res.body))
      .subscribe(res => {
        this.messages = this.messages.concat(res);
      });
  }

  sendMessage() {
    if (isEmpty(this.contentMessage)) {
      return;
    }
    const content: IMessage = new Message();
    content.message = this.contentMessage;
    content.groupId = this.groupId;
    content.senderId = this.currentAccount.id;
    content.title = this.title;
    this.messageService.sendMessage(content).subscribe(res => {
      this.onClickMessageByGroup(this.groupId, this.title, this.description, false);
      this.contentMessage = '';
    });
  }

  onScrolGroup() {
    this.countGroup++;
    this.findAllGroup(true);
  }

  onScrolMessage() {
    this.countMessage++;
    this.onClickMessageByGroup(this.groupId, this.title, this.description, true);
  }

  linkToProfile(userId: number) {
    this.route.navigate([`admin/profile/${userId}`]);
  }

  newMessage() {
    const modalRef = this.modalService.open(NewMessageModalComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.senderId = this.currentAccount.id;
    modalRef.result.then(
      result => {
        if (result) {
          this.findAllGroup(false);
        }
      },
      reason => {
        // console.log(reason);
      }
    );
  }

  mapUser(user: IUserBasic[]) {
    const result = user.map(item => item.firstName + ' ' + item.lastName).join(', ');
    if (result.length > 30) {
      return result.slice(0, 30);
    }
    return result;
  }

  showProfile(user: IUserBasic[]) {
    const modalRef = this.modalService.open(ReceiverModalComponent, { size: 'sm', backdrop: 'static' });
    modalRef.componentInstance.receivers = user;
  }

  markAsRead(message: IMessage) {
    this.messageService.markAsRead(message.id).subscribe(res => (message.read = true), err => alert(err.message));
    this.openMessageModal(message);
  }

  openMessageModal(message: IMessage) {
    const modalRef = this.modalService.open(MessageReadModalComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.message = message;
  }
}
