import { Pipe, PipeTransform, Injectable } from '@angular/core';
@Pipe({
    name: 'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform {
    transform(items: any[], value: string): any[] {
        if (!items) {
            return [];
        }
        // if (!field || !value) {
        if (!value) {
            return items;
        }
        // singleItem[field].toLowerCase().includes(value.toLowerCase()));
        return items.filter(singleItem => this.objToString(singleItem).includes(value.toLowerCase()));
    }

    objToString(obj): String {
        let str = '';
        for (const p in obj) {
            if ({}.hasOwnProperty.call(obj, p) && obj[p] ) { // if (obj.hasOwnProperty(p) && obj[p]) {
                if ('[object Object]' === String(obj[p])) {
                    str += this.objToString(obj[p]) + ' ';
                } else {
                    str += String(obj[p]).toLowerCase() + ' ';
                }
            }
        }
        return str;
    }
}
