import { Route } from '@angular/router';
import { UserRouteAccessService } from 'app/core';

import { AdmindashboardComponent } from './admindashboard.component';

export const admindashboardRoute: Route = {
  path: 'administration',
  component: AdmindashboardComponent,
  data: {
    pageTitle: 'Administration',
    authorities: ['ROLE_ADMIN']
  },
  canActivate: [UserRouteAccessService]
};
