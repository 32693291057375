import { Component, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { IDashboard, Dashboard } from 'app/shared/model/dashboard.model';
import { map, filter } from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { JhiAlertService } from 'ng-jhipster';
import { AccountService, Account } from 'app/core';
import { IDiscussionGroup } from 'app/shared/model/discussion-group.model';
import { forkJoin } from 'rxjs';
import { IUser, User } from 'app/shared/model/user.model';
import { MessageService } from '../messages/message.service';
import { IMessage } from 'app/shared/model/message.model';
import { Router } from '@angular/router';
import { ProfileService } from '../profile/profile.service';
import { UserType } from 'app/shared/model/enumerations/user-type.model';
@Component({
  selector: 'gp-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class JhiDashboardComponent implements OnInit {
  dashboard: IDashboard;
  currentAccount: Account;
  newAppliedHealth: IUser[];
  speciallist: IUser[];
  messages: IMessage[];
  user: IUser;
  unreadMessages = 0;

  constructor(
    private dashboardService: DashboardService,
    private jhiAlertService: JhiAlertService,
    private accountService: AccountService,
    private messageService: MessageService,
    private profileService: ProfileService,
    private route: Router
  ) {}
  ngOnInit() {
    this.accountService.changeUsername(localStorage.getItem('loggedinUsername'));
    this.dashboard = new Dashboard();
    this.user = new User();
    this.speciallist = [];
    this.messages = [];
    this.newAppliedHealth = [];
    this.accountService.identity().then(account => {
      this.currentAccount = account;
      this.getPostGroup();
      this.loadAll();
    });
  }

  loadAll() {
    forkJoin([
      this.dashboardService.findUserByUserType({
        page: 0,
        size: 5,
        sort: ['createdDate,desc'],
        type: UserType.SPECIALIST
      }),
      this.dashboardService.findUserByUserType({
        page: 0,
        size: 5,
        sort: ['createdDate,desc'],
        type: UserType.APPLIED_HEALTH
      }),
      this.messageService.findMessageNew({
        page: 0,
        size: 5,
        sort: ['createdDate,desc'],
        user: this.currentAccount.id
      }),
      this.profileService.findUser(this.currentAccount.id),
      this.messageService.countUnreadMessagesByUser(this.currentAccount.id)
    ]).subscribe(([res1, res2, res3, res4, res5]) => {
      this.speciallist = res1.body;
      this.newAppliedHealth = res2.body;
      this.messages = res3.body;
      this.user = res4.body;
      // this.accountService.changeUsername(this.user.firstName + " " + this.user.lastName);
      this.unreadMessages = res5;
    });
  }

  onLinkGroup(groupId: number) {
    this.route.navigate([`/admin/groups/${this.currentAccount.id}`], { queryParams: { group: groupId } });
  }

  getPostGroup() {
    this.dashboardService
      .getPublicGroupPosts({
        user: this.currentAccount.id,
        page: 0,
        size: 10,
        sort: ['createdDate,desc']
      })
      .pipe(
        filter((mayBeOk: HttpResponse<IDiscussionGroup[]>) => mayBeOk.ok),
        map((response: HttpResponse<IDiscussionGroup[]>) => response.body)
      )
      .subscribe(
        (res: IDiscussionGroup[]) => (this.dashboard.publicGroupPost = res),
        (res: HttpErrorResponse) => this.onError(res.message)
      );
  }

  linkToProfile(userId) {
    this.route.navigate([`admin/profile/${userId}`]);
  }

  linkToMessageGroup(groupId) {
    if (groupId === null) {
      this.route.navigate([`admin/messages`]);
      return;
    }
    this.route.navigate([`admin/messages/${groupId}`]);
  }

  protected onError(errorMessage: string) {
    this.jhiAlertService.error(errorMessage, null, null);
  }

  splitStr(name: string, length: number) {
    if (name.length >= length) {
      return name.substr(0, length).concat('...');
    }
    return name;
  }

}
