import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IFeedBack } from 'app/shared/model/feed-back.model';
import { IReportPost } from 'app/shared/model/report-post.model';
import { createRequestOption } from 'app/shared';
import { SERVER_API_URL } from 'app/app.constants';

type EntityFeedBack = HttpResponse<IFeedBack[]>;
type EntityReportPost = HttpResponse<IReportPost[]>;
@Injectable({
  providedIn: 'root'
})
export class AdmindashboardService {
  private resourceUrl = SERVER_API_URL + 'api/front/feedback';
  private profileUrl = SERVER_API_URL + 'api/front/profiles';
  private userUrl = SERVER_API_URL + 'api/users';
  private reportPostUrl = SERVER_API_URL + 'api/report-posts';

  constructor(private service: HttpClient) {}

  public findAllByType(req?: any): Observable<EntityFeedBack> {
    const options = createRequestOption(req);
    return this.service.get<IFeedBack[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  public suspendUser(userId: number): Observable<any> {
    return this.service.get(`${this.profileUrl}/${userId}/suspend`);
  }

  public findUserByLogin(login: string): Observable<any> {
    return this.service.get(`${this.userUrl}/${login}`);
  }

  public findReportPost(req?: any): Observable<EntityReportPost> {
    const options = createRequestOption(req);
    return this.service.get<IReportPost[]>(this.reportPostUrl, {params: options, observe: 'response'});
  }

  public deleteGroupId(groupId: number): Observable<any> {
    return this.service.delete(`${this.reportPostUrl}/${groupId}`);
  }
}
