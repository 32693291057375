import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Setting } from 'app/shared/model/setting.model';
import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { GroupNotificationDiscussion } from 'app/shared/model/groupnotification-discussion.model';

type EntityResponseType = HttpResponse<Setting>;
type GroupNotificationResponseType = HttpResponse<GroupNotificationDiscussion[]>;
@Injectable({
  providedIn: 'root'
})
export class SettingService {

  public resourceUrl = SERVER_API_URL + 'api/notification-settings';
  public disccussGroupUrl = SERVER_API_URL + 'api/discussion-groups';
  public groupNotificationUrl = SERVER_API_URL + 'api/group-notifications';
  constructor(private http: HttpClient) { }

  findGroupByUser(id: number): Observable<any> {
    return this.http.get(`${this.disccussGroupUrl}/${id}/user`);
  }
  
  findDiscussionGroupByOwner(id: number): Observable<any> {
    return this.http.get(`${this.disccussGroupUrl}/owner/${id}`);
  }

  findByUser(id: number): Observable<EntityResponseType> {
    return this.http.get<Setting>(`${this.resourceUrl}/user/${id}`, { observe: 'response' });
  }
  
  findGroupNotificationByNotificationId(id: number, req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<GroupNotificationDiscussion[]>(`${this.groupNotificationUrl}/${id}/notification`, { params: options, observe: 'response'});
  }

  saveForm(setting: Setting): Observable<any> {
    return this.http.put(`${this.resourceUrl}/user`,setting, { observe: 'response' });
  }
}
