import { Moment } from 'moment';
import { Severity } from './enumerations/severity.model';
import { IUser } from './user.model';

export interface IMessage {
  id?: number;
  title?: string;
  message?: string;
  severity?: Severity;
  url?: string;
  deliveried?: boolean;
  read?: boolean;
  senderId?: number;
  groupId?: number;
  groupTitle?: string;
  user?: IUser;
  createdDate?: Moment;
}

export class Message implements IMessage {
  constructor(
    public id?: number,
    public title?: string,
    public message?: string,
    public severity?: Severity,
    public url?: string,
    public deliveried?: boolean,
    public read?: boolean,
    public senderId?: number,
    public groupId?: number,
    public groupTitle?: string,
    public user?: IUser,
    public createdDate?: Moment
  ) {
    this.id = id || null;
    this.title = title || null;
    this.message = message || null;
    this.severity = severity || Severity.INFO;
    this.url = url || null;
    this.deliveried = deliveried || true;
    this.read = read || false;
    this.senderId = senderId || null;
    this.groupId = groupId || null;
    this.groupTitle = groupTitle || null;
    this.user = user || null;
    this.createdDate = createdDate || null;
  }
}
