import { UserType } from 'app/shared/model/enumerations/user-type.model';
import { UserStatus } from 'app/shared/model/enumerations/user-status.model';
import { ProfileVisibility } from 'app/shared/model/enumerations/profile-visibility.model';
import { UserSpecialityType } from 'app/shared/model/enumerations/user-speciality-type.model';
import { IUser, User } from '../../core/user/user.model';
import { ISpeciality } from 'app/shared/model/speciality.model';
import { IDiscussionGroup } from 'app/shared/model/discussion-group.model';
import { IMedicalPractice } from 'app/shared/model/medical-practice.model';
import { IEndorsementUser } from './endorsement-user.model';
import { IEndorsement } from './endorserment.model';

export interface IUserProfile extends IUser {
  id?: number;
  about?: any;
  phone?: string;
  type?: UserType;
  status?: UserStatus;
  visibility?: ProfileVisibility;
  ahpraNumber?: string;
  practiceName?: string;
  specialityType?: UserSpecialityType;
  userId?: number;
  discussions?: IDiscussionGroup[];
  discussionGroups?: IDiscussionGroup[];
  medicalPractices?: IMedicalPractice[];
  specialities?: ISpeciality[];
  endorserment?: IEndorsementUser[];
  imageUrl?: string;
  shortname?: string;
  userType?: string;
}

export class UserProfile extends User implements IUserProfile {
  constructor(
    public id?: number,
    public about?: any,
    public phone?: string,
    public type?: UserType,
    public status?: UserStatus,
    public visibility?: ProfileVisibility,
    public ahpraNumber?: string,
    public practiceName?: string,
    public specialityType?: UserSpecialityType,
    public userId?: number,
    public login?: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public activated?: boolean,
    public langKey?: string,
    public authorities?: any[],
    public createdBy?: string,
    public createdDate?: Date,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Date,
    public password?: string,
    public discussions?: IDiscussionGroup[],
    public discussionGroups?: IDiscussionGroup[],
    public medicalPractices?: IMedicalPractice[],
    public specialities?: ISpeciality[],
    public endorserment? :IEndorsement[],
    public findByUser? : string,
    public imageUrl? : string,
    public shortname? : string,
    public userType?: string
  ) {
    super(
      id,
      login,
      firstName,
      lastName,
      email,
      activated,
      langKey,
      authorities,
      createdBy,
      createdDate,
      lastModifiedBy,
      lastModifiedDate,
      password
    );
    this.id = id ? id : null;
    this.about = about ? about : null;
    this.phone = phone ? phone : null;
    this.type = type ? type : null;
    this.status = status ? status : null;
    this.visibility = visibility ? visibility : null;
    this.ahpraNumber = ahpraNumber ? ahpraNumber : null;
    this.practiceName = practiceName ? practiceName : null;
    this.userId = userId ? userId : null;
    this.discussions = discussions ? discussions : [];
    this.discussionGroups = discussionGroups ? discussionGroups : [];
    this.medicalPractices = medicalPractices ? medicalPractices : [];
    this.specialities = specialities ? specialities : [];
    this.endorserment = endorserment ? endorserment : [];
    this.imageUrl = imageUrl ? imageUrl : null;
    this.shortname = shortname ? shortname : null;
    this.userType = userType ? userType : null;
  }
}