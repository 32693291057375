import { Route } from '@angular/router';

import { ReviewSpecialitiesComponent } from './review-specialities.component';
import { JhiResolvePagingParams } from 'ng-jhipster';
import { UserRouteAccessService } from 'app/core';

export const reviewSpecialitiesRoute: Route = {
  path: 'reviewSpecialities',
  component: ReviewSpecialitiesComponent,
  resolve: {
    pagingParams: JhiResolvePagingParams
  },
  data: {
    authorities: ['ROLE_ADMIN'],
    defaultSort: 'id,asc',
    pageTitle: 'Administration Specialities'
  },
  canActivate: [UserRouteAccessService]
};
