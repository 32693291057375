import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DiscussionGroup } from 'app/shared/model/discussion-group.model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'gp-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {
  @Input() public inputModel: any;
  discussionGroup: any;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.inputModel == null || this.inputModel.id == null) {
      this.discussionGroup = new DiscussionGroup();
    } else {
      this.discussionGroup = this.inputModel;
    }
  }

  checkValidate() {
    if (this.checkNullOrEmpty(this.discussionGroup.name) || this.checkNullOrEmpty(this.discussionGroup.description)) {
      return true;
    }
    return false;
  }

  checkNullOrEmpty(val: string) {
    if (isNullOrUndefined(val) || val === '') {
      return true;
    }
    return false;
  }

  passBack() {
    this.activeModal.close(this.discussionGroup);
  }
}
