import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgJhipsterModule } from 'ng-jhipster';

import { AuthInterceptor } from './blocks/interceptor/auth.interceptor';
import { AuthExpiredInterceptor } from './blocks/interceptor/auth-expired.interceptor';
import { ErrorHandlerInterceptor } from './blocks/interceptor/errorhandler.interceptor';
import { NotificationInterceptor } from './blocks/interceptor/notification.interceptor';
import { GpplcrSharedModule } from 'app/shared';
import { GpplcrCoreModule } from 'app/core';
import { GpplcrAppRoutingModule } from './app-routing.module';
import { GpplcrHomeModule } from 'app/home';
import { GpplcrAccountModule } from './account/account.module';
import { GpplcrAdminModule } from './admin/admin.module';
import * as moment from 'moment';
// jhipster-needle-angular-add-module-import JHipster will add new module here
import { JhiMainComponent, NavbarComponent, FooterComponent, PageRibbonComponent, ErrorComponent } from './layouts';
import { GpplcrModule } from 'app/gpplcr/gpplcr.module';
import { NavbarMainComponent } from 'app/layouts';
import { LoaderComponent } from './layouts/loader/loader.component';
import { LoaderInterceptorService } from './layouts/loader/loader.interceptor';
import { LoaderService } from './layouts/loader/loader.service';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faUser,
  faSort,
  faSortUp,
  faSortDown,
  faSync,
  faEye,
  faBan,
  faTimes,
  faArrowLeft,
  faSave,
  faPlus,
  faPencilAlt,
  faBars,
  faThList,
  faUserPlus,
  faRoad,
  faTachometerAlt,
  faHeart,
  faList,
  faBell,
  faBook,
  faHdd,
  faFlag,
  faWrench,
  faClock,
  faCloud,
  faSignOutAlt,
  faSignInAlt,
  faCalendarAlt,
  faSearch,
  faTrashAlt,
  faAsterisk,
  faTasks,
  faHome,
  faInfoCircle,
  faEnvelope,
  faThumbsUp,
  faExclamationTriangle,
  faStar,
  faEllipsisH,
  faAddressCard,
  faMapMarked
} from '@fortawesome/free-solid-svg-icons';

import '../content/scss/vendor.scss';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    FontAwesomeModule,
    BrowserModule,
    NgxWebstorageModule.forRoot({ prefix: 'gp', separator: '-' }),
    NgJhipsterModule.forRoot({
      // set below to true to make alerts look like toast
      alertAsToast: false,
      alertTimeout: 5000
    }),
    GpplcrSharedModule.forRoot(),
    GpplcrCoreModule,
    GpplcrHomeModule,
    GpplcrAccountModule,
    GpplcrAdminModule,
    // jhipster-needle-angular-add-module JHipster will add new module here
    GpplcrAppRoutingModule,
    NgxMaterialTimepickerModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    GpplcrModule
  ],
  declarations: [
    JhiMainComponent,
    NavbarComponent,
    NavbarMainComponent,
    ErrorComponent,
    PageRibbonComponent,
    FooterComponent,
    LoaderComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationInterceptor,
      multi: true
    },
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    }
  ],
  bootstrap: [JhiMainComponent]
})
export class GpplcrAppModule {
  constructor(private dpConfig: NgbDatepickerConfig, private library: FaIconLibrary) {
    this.dpConfig.minDate = { year: moment().year() - 100, month: 1, day: 1 };
    library.addIcons(faUser);
    library.addIcons(faSort);
    library.addIcons(faSortUp);
    library.addIcons(faSortDown);
    library.addIcons(faSync);
    library.addIcons(faEye);
    library.addIcons(faBan);
    library.addIcons(faTimes);
    library.addIcons(faArrowLeft);
    library.addIcons(faSave);
    library.addIcons(faPlus);
    library.addIcons(faPencilAlt);
    library.addIcons(faBars);
    library.addIcons(faHome);
    library.addIcons(faThList);
    library.addIcons(faUserPlus);
    library.addIcons(faRoad);
    library.addIcons(faTachometerAlt);
    library.addIcons(faHeart);
    library.addIcons(faList);
    library.addIcons(faBell);
    library.addIcons(faTasks);
    library.addIcons(faBook);
    library.addIcons(faHdd);
    library.addIcons(faFlag);
    library.addIcons(faWrench);
    library.addIcons(faClock);
    library.addIcons(faCloud);
    library.addIcons(faSignOutAlt);
    library.addIcons(faSignInAlt);
    library.addIcons(faCalendarAlt);
    library.addIcons(faSearch);
    library.addIcons(faTrashAlt);
    library.addIcons(faAsterisk);
    library.addIcons(faInfoCircle);
    library.addIcons(faEnvelope);
    library.addIcons(faThumbsUp);
    library.addIcons(faExclamationTriangle);
    library.addIcons(faStar);
    library.addIcons(faEllipsisH);
    library.addIcons(faAddressCard);
    library.addIcons(faMapMarked);
  }
}
