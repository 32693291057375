import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { errorRoute, navbarMainRoute, navbarRoute } from './layouts';

const LAYOUT_ROUTES = [navbarRoute, navbarMainRoute, ...errorRoute];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'admin',
          loadChildren: () => import('app/admin/admin.module').then(mod => mod.GpplcrAdminModule) 
        },
        {
          path: '',
          loadChildren: () => import('app/gpplcr/gpplcr.module').then(mod => mod.GpplcrModule) 
        },
        ...LAYOUT_ROUTES
      ],
      { preloadingStrategy: PreloadAllModules }
    )
  ],
  exports: [RouterModule]
})
export class GpplcrAppRoutingModule {}
