import { Moment } from 'moment';
import { Severity } from './enumerations/severity.model';
import { IUser } from './user.model';

export interface IMessageGroup {
  id?: number;
  title?: string;
  message?: string;
  servity?: Severity;
  url?: string;
  deliveried?: boolean;
  receiverIds?: string;
  receivers?: IUserBasic[];
  senderId?: number;
  user?: IUser;
  createdDate?: Moment;
}

export class MessageGroup implements IMessageGroup {
  constructor(
    public id?: number,
    public title?: string,
    public message?: string,
    public servity?: Severity,
    public url?: string,
    public deliveried?: boolean,
    public receiverIds?: string,
    public senderId?: number,
    public user?: IUser,
    public createdDate?: Moment
  ) {
    this.id = id || null;
    this.title = title || null;
    this.message = message || null;
    this.servity = servity || Severity.INFO;
    this.url = url || null;
    this.deliveried = deliveried || true;
    this.receiverIds = receiverIds || null;
    this.senderId = senderId || null;
    this.user = user || null;
    this.createdDate = createdDate || null;
  }
}

export interface IUserBasic {
  id?: number;
  firstName?: string;
  lastName?: string;
}
