import { Component } from '@angular/core';

@Component({
  selector: 'gp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['footer.scss']
})
export class FooterComponent {
  curryear = (new Date()).getFullYear();
}
