import { Route } from '@angular/router';

import { EndorsesComponent } from './endorses.component';

export const endorsesRoute: Route = {
    path: 'endorses',
    component: EndorsesComponent,
    data: {
      pageTitle: 'Endorses'
    }
  };
