export interface IDiscussionLike {
    id?: number;
    liked?: boolean;
    userId?: number;
    discussionId?: number;
}

export class DiscussionLike implements DiscussionLike {
    constructor(public id?: number,
        public liked?: boolean,
        public userId?: number,
        public discussionId?: number) {
            this.id = id || null;
            this.liked = liked || null;
            this.userId = userId || null;
            this.discussionId = discussionId || null;
        }
}