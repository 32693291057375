import { Component, OnInit, Renderer, ViewChild, ElementRef, NgZone, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { EMAIL_ALREADY_USED_TYPE, LOGIN_ALREADY_USED_TYPE, isEmpty } from 'app/shared';
// import { Address, IAddress } from 'app/shared/model/address.model';
import { IMedicalPractice, MedicalPractice } from 'app/shared/model/medical-practice.model';
import { User } from 'app/shared/model/user.model';
import { Register } from './register.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'gp-register',
  templateUrl: './register.component.html',
  styles: [
    `
      agm-map {
        height: 300px;
      }
      ::ng-deep .pac-container {
        z-index: 9999;
      }
    `
  ]
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {
  doNotMatch: string;
  error: string;
  errorEmailExists: string;
  errorUserExists: string;
  success: boolean;
  modalRef: NgbModalRef;
  account: Account;
  registerAccount: {};
  isNextForm: boolean;
  registrationProcessed: boolean;
  
  reqUserType: string;
  reqUserTypeName: string;
  invitedBy: string;
  registrationToken: string;
  
  reqFirstName: string;
  reqLastName: string;
  reqEmail: string;
  reqPhone: string;
  
  mpModel: IMedicalPractice;
  
  ahpraNumber: string;
  specialityType: string;
  
  newUser: User;
  
  private geoCoder;
  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;

  registerForm = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(254), Validators.email]],
    password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
    address: [''],
    phone: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
    userType: [''],
    // medical practice address
    ahpraNumber: [''],
    specialityType: [''],
    practiceName: [''],
    searchaddress: [''],
    lat: [''],
    lng: [''],
    unit: [''],
    number: [''],
    street: [''],
    city: [''],
    state: [''],
    postCode: ['']
  });

  constructor(
    private registerService: Register,
    private elementRef: ElementRef,
    private renderer: Renderer,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.mpModel = new MedicalPractice();
    this.success = false;
    this.reqUserType = this.route.snapshot.queryParamMap.get('userType');
    if (this.reqUserType == null) {
      this.reqUserType = 'GP';
    }
    if (this.reqUserType === 'SPECIALIST') {
        this.reqUserTypeName = 'Specialist';
    } else if (this.reqUserType === 'APPLIED_HEALTH') {
        this.reqUserTypeName = 'Allied Health';
    } else {
      this.reqUserTypeName = 'General Practitioner';
    }
    
    this.invitedBy = this.route.snapshot.queryParamMap.get('invitedBy');
    this.registrationToken = this.route.snapshot.queryParamMap.get('registrationToken');
    this.reqFirstName = this.route.snapshot.queryParamMap.get('firstName');
    this.reqLastName = this.route.snapshot.queryParamMap.get('lastName');
    this.reqEmail = this.route.snapshot.queryParamMap.get('email');
    this.reqPhone = this.route.snapshot.queryParamMap.get('phone');
    
    this.registerForm.patchValue({
      firstName: this.reqFirstName, 
      lastName: this.reqLastName,
      email: this.reqEmail,
      phone: this.reqPhone
    });
    
    this.registrationProcessed = false;
  }

  nextForm() {
    this.loadMap();
    this.registerAccount = {};
    const firstName = this.registerForm.get(['firstName']).value;
    const lastName = this.registerForm.get(['lastName']).value;
    const login = this.registerForm.get(['email']).value;
    const email = this.registerForm.get(['email']).value;
    const password = this.registerForm.get(['password']).value;
    const address = this.registerForm.get(['address']).value;
    const phone = this.registerForm.get(['phone']).value;
    // const userType = this.registerForm.get(['userType']).value;
    const userType = this.reqUserType;
    if (password !== this.registerForm.get(['confirmPassword']).value) {
      this.doNotMatch = 'ERROR';
    } else {
      this.registerAccount = { ...this.registerAccount, firstName, lastName, login, email, password, address, phone, userType };
      this.doNotMatch = null;
      this.error = null;
      this.errorUserExists = null;
      this.errorEmailExists = null;
      this.registerAccount = { ...this.registerAccount, langKey: 'en' };
      this.isNextForm = true;
    }

    this.registerAccount = { ...this.registerAccount, invitedBy: this.invitedBy, registrationToken: this.registrationToken };
    
    if (this.reqUserType === 'GP') {
      this.isNextForm = false;
      this.executeRegister();
    }
  }

  register() {
    const ahpraNumber = this.ahpraNumber;
    const specialityType = this.specialityType;
    const practiceName = this.mpModel.name;
    const searchaddress = this.mpModel.line;
    const unit = this.mpModel.unit;
    const number = this.mpModel.number;
    const street = this.mpModel.street;
    const city = this.mpModel.city;
    const state = this.mpModel.state;
    const postCode = this.mpModel.postCode;
    const lat = this.mpModel.lat;
    const lng = this.mpModel.lng;
    const visibility = 'PUBLIC';
    const status = 'NEW';
    this.registerAccount = {
      ...this.registerAccount,
      ahpraNumber,
      specialityType,
      practiceName,
      searchaddress,
      unit,
      number,
      street,
      city,
      state,
      postCode,
      visibility,
      status,
      lat,
      lng
    };
    this.executeRegister();
  }

  executeRegister() {
    this.registerService.save(this.registerAccount).subscribe(
      responseSuccess => {
        this.newUser = responseSuccess;
        this.mpModel.userId = this.newUser.id;
        this.mpModel.userLogin = this.newUser.login;
        if (this.reqUserType !== 'GP') {
            this.registerService.saveMp(this.mpModel).subscribe(
                () => {
                    this.success = true;
                    this.router.navigate(['./help']);
                },
                response => {
                  this.processError(response);
                  this.registrationProcessed = true;
                }
            );
        } else {
            this.success = true;
            this.router.navigate(['./help']);
        }
      },
      responseError => {
        this.processError(responseError);
        this.registrationProcessed = true;
      }
    );
  }

  private processError(response: HttpErrorResponse) {
    this.success = null;
    if (response.status === 400 && response.error.type === LOGIN_ALREADY_USED_TYPE) {
      this.errorUserExists = 'ERROR';
    } else if (response.status === 400 && response.error.type === EMAIL_ALREADY_USED_TYPE) {
      this.errorEmailExists = 'ERROR';
    } else {
      this.error = response.error.detail;
    }
  }

  loadMap() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.mpModel.lat = place.geometry.location.lat();
          this.mpModel.lng = place.geometry.location.lng();
          this.getAddress(this.mpModel.lat, this.mpModel.lng);
        });
      });
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.mpModel.lat = isNullOrUndefined(this.mpModel.lat) ? position.coords.latitude : this.mpModel.lat;
        this.mpModel.lng = isNullOrUndefined(this.mpModel.lng) ? position.coords.longitude : this.mpModel.lng;
        this.getAddress(this.mpModel.lat, this.mpModel.lng);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.mpModel.lat = $event.coords.lat;
    this.mpModel.lng = $event.coords.lng;
    this.getAddress(this.mpModel.lat, this.mpModel.lng);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.mpModel.line = results[0].formatted_address;
          this.getDetailAddress(results);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getDetailAddress(results: any) {
    /* eslint-disable no-console */
    console.log('Address result=', results);
    if (results.length) {
      this.mpModel.number = null;
      this.mpModel.street = null;
      this.mpModel.postCode = null;
      this.mpModel.country = null;
      this.mpModel.city = null;
      this.mpModel.state = null;
      results[0].address_components.forEach((addressComponent, index) => {
        if (addressComponent.types.includes('street_number')) {
          this.mpModel.number = addressComponent.long_name;
        }
        if (addressComponent.types.includes('route')) {
          this.mpModel.street = addressComponent.long_name;
        }
        if (addressComponent.types.includes('postal_code')) {
          this.mpModel.postCode = addressComponent.long_name;
        }
        if (addressComponent.types.includes('country')) {
          this.mpModel.country = addressComponent.long_name;
        }
        if (addressComponent.types.includes('locality')) {
          this.mpModel.unit = addressComponent.long_name;
        }
        if (addressComponent.types.includes('administrative_area_level_1')) {
          this.mpModel.state = addressComponent.long_name;
          if (isEmpty(this.mpModel.city)) {
            this.mpModel.city = addressComponent.long_name;
          }
        }
        if (addressComponent.types.includes('administrative_area_level_2')) {
          this.mpModel.street += ', ' + addressComponent.long_name;
        }
      });
    }
  }

  ngAfterViewInit() {
    document.getElementsByTagName('body')[0].classList.add('landing-page');
  }

  ngOnDestroy() {
    document.getElementsByTagName('body')[0].classList.remove('landing-page');
  }

}
