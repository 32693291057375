import { Routes } from '@angular/router';

import { MessagesComponent } from './messages.component';

export const messagesRoute: Routes = [
  {
    path: 'messages',
    component: MessagesComponent,
    data: {
      pageTitle: 'messages'
    },
  },
  {
    path: 'messages/:id',
    component: MessagesComponent,
    data: {
      pageTitle: 'messages'
    },
  }
];
