import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupsService } from './groups.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { IDiscussionGroup } from 'app/shared/model/discussion-group.model';
import { map } from 'rxjs/operators';
import { JhiAlertService } from 'ng-jhipster';
import { splitStr } from 'app/shared';
import { Account, AccountService, User } from 'app/core';
import { IDiscussionUser } from 'app/shared/model/discussion-user.model';
import { Discussion, IDiscussion } from 'app/shared/model/discussion.model';
import { IDiscussionLike, DiscussionLike } from 'app/shared/model/discussion-like.model';
import { isNullOrUndefined } from 'util';
import { GroupsModalService } from './groups-modal.service';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { IFeedBack, FeedBack } from 'app/shared/model/feed-back.model';
import { Router, ActivatedRoute } from '@angular/router';
import { IDiscussions, Discussions } from 'app/shared/model/discussions.model';
import { IGroupFollow, GroupFollow } from 'app/shared/model/group-follow.model';

@Component({
  selector: 'gp-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  myGroup: IDiscussionGroup[];
  allGroup: IDiscussionGroup[];
  discussionInfo: IDiscussions;
  discussions: IDiscussionUser[];
  newGroup: IDiscussionGroup;
  currentGroup: IDiscussionGroup;
  currentAccount: Account;
  textSearch: string;
  textSearchAll: string;
  content: string;
  count: number;
  feedBack: IFeedBack;
  countByGroup;
  countMyGroup;
  countAllGroup;
  groupId: number;
  userId: number;
  groupDesc: string;

  @ViewChild(ContextMenuComponent, { static: true }) contextMenu: ContextMenuComponent;

  constructor(
    private groupService: GroupsService,
    private accountService: AccountService,
    private jhiAlertService: JhiAlertService,
    private groupModelService: GroupsModalService,
    private ngContextService: ContextMenuService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.accountService.changeUsername(localStorage.getItem('loggedinUsername'));
    this.discussionInfo = new Discussions();
    this.count = 0;
    this.countMyGroup = 0;
    this.countAllGroup = 0;
    this.myGroup = [];
    this.allGroup = [];
    this.textSearch = null;
    this.textSearchAll = null;
    this.feedBack = new FeedBack();
    this.countByGroup = 0;
    this.currentAccount = new Account(null, false, null, null, null, null, null, null, null);
    this.currentGroup = null;

    this.accountService.identity().then(account => {
      this.currentAccount = account;
      this.userId = account.id;
      if (!isNullOrUndefined(this.activatedRoute.snapshot.paramMap.get('id'))) {
        this.userId = +this.activatedRoute.snapshot.paramMap.get('id');
      }
      if (!isNullOrUndefined(this.activatedRoute.snapshot.queryParamMap.get('group'))) {
        this.groupId = +this.activatedRoute.snapshot.queryParamMap.get('group');
      }
      this.getMyGroup(true);
      this.getAllGroup(true);
    });
  }

  getMyGroup(isInit: boolean) {
    if (isInit) {
      this.myGroup = [];
      this.countMyGroup = 0;
    }
    this.groupService
      .getGroup(this.textSearch, {
        user: this.userId,
        page: this.countMyGroup,
        size: 10,
        sort: ['createdDate,desc']
      })
      .pipe(
        // filter((mayBeOk: HttpResponse<IDiscussionGroup[]>) => mayBeOk.ok),
        map((response: HttpResponse<IDiscussionGroup[]>) => response.body)
      )
      .subscribe(
        (res: IDiscussionGroup[]) => {
          this.myGroup = this.myGroup.concat(res);
          if (isInit && res.length > 0) {
            this.currentGroup = this.myGroup[0];
            const discussion: number = isNullOrUndefined(this.groupId) ? this.currentGroup.id : this.groupId;
            this.getByGroupId(discussion, true);
          }
        },
        (res: HttpErrorResponse) => this.onError(res.message)
      );
  }

  getAllGroup(isInit: boolean) {
    if (isInit) {
      this.countAllGroup = 0;
      this.allGroup = [];
    }
    this.groupService
      .getGroup(this.textSearchAll, {
        page: this.countAllGroup,
        size: 10,
        sort: ['createdDate,desc']
      })
      .pipe(map((response: HttpResponse<IDiscussionGroup[]>) => response.body))
      .subscribe(
        (res: IDiscussionGroup[]) => (this.allGroup = this.allGroup.concat(res)),
        (res: HttpErrorResponse) => this.onError(res.message)
      );
  }

  getByGroup(group: IDiscussionGroup, isInit: boolean) {
    this.getByGroupId(group.id, isInit);
    this.currentGroup = group;
    // eslint-disable-next-line no-console
    console.log(group);
  }

  getByGroupId(groupId: number, isInit: boolean) {
    this.discussionInfo.groupId = groupId;
    if (isInit) {
      this.discussions = [];
      this.countByGroup = 0;
    }
    this.groupService
      .getDiscussionByGroup({
        user: this.userId,
        group: groupId,
        page: this.countByGroup,
        size: 10,
        sort: ['createdDate,desc']
      })
      .pipe(
        // filter((mayBeOk: HttpResponse<IDiscussionGroup[]>) => mayBeOk.ok),
        map((response: HttpResponse<IDiscussions>) => response.body)
      )
      .subscribe(
        (res: IDiscussions) => {
          this.discussionInfo = res;
          this.discussions = this.discussions.concat(res.discussions);
        },
        (res: HttpErrorResponse) => this.onError(res.message)
      );
  }

  sendDiscussion() {
    if (isNullOrUndefined(this.content)) {
      return;
    }
    const discussion: IDiscussion = new Discussion();
    discussion.groupId = this.discussionInfo.groupId;
    discussion.ownerId = this.currentAccount.id;
    discussion.ownerLogin = this.currentAccount.login;
    discussion.content = this.content;
    discussion.summary = this.discussionInfo.groupName;
    this.groupService.sendDiscussion(discussion).subscribe(res => {
      this.content = null;
      this.discussions.unshift(res);
    });
  }

  createGroup() {
    this.groupModelService.createGroup(this.newGroup, this.handleCreateGroup);
  }

  handleCreateGroup = (result: any) => {
    this.newGroup = result;
    this.newGroup.isPublic = true;
    this.newGroup.ownerId = this.currentAccount.id;
    this.newGroup.ownerLogin = this.currentAccount.login;
    this.groupService.createGroup(this.newGroup).subscribe(res => {
      this.countMyGroup = 0;
      this.getMyGroup(true);
    });
  };

  protected onError(errorMessage: string) {
    alert(errorMessage);
    this.jhiAlertService.error(errorMessage, null, null);
  }

  splitStr(name: string, length: number) {
    return splitStr(name, length);
  }

  async setLike(discussionId: number, event, index: number) {
    const discussionLike: IDiscussionLike = new DiscussionLike();
    discussionLike.discussionId = discussionId;
    discussionLike.userId = this.currentAccount.id;
    discussionLike.liked = event.target.checked;
    await this.groupService
      .likeDiscussion(discussionLike)
      .toPromise()
      .then(res => {
        this.discussions[index].liked = event.target.checked ? this.discussions[index].liked + 1 : this.discussions[index].liked - 1;
      });
  }

  reportPost(user: User) {
    this.groupModelService.reportPost(this.currentAccount.id, user, this.reportPostUser);
  }

  reportPostUser = result => {
    this.groupService.reportPost(result).subscribe(res => {
      alert('success');
    });
  };

  onModalScrollDown() {
    this.countByGroup++;
    this.getByGroupId(this.discussionInfo.groupId, false);
  }

  onScrollMyGroup() {
    this.countMyGroup++;
    this.getMyGroup(false);
  }

  onScrollAllGroup() {
    this.countAllGroup++;
    this.getAllGroup(false);
  }

  clearText() {
    this.textSearch = null;
  }

  clearAllText() {
    this.textSearchAll = null;
  }

  onClickProfileUser(userId: number) {
    this.router.navigate([`admin/profile/${userId}`]);
  }

  onRightClick($event: KeyboardEvent, discussion: IDiscussionUser) {
    if (discussion.user.id === this.currentAccount.id) {
      return;
    }
    this.ngContextService.show.next({
      contextMenu: this.contextMenu,
      event: $event,
      item: discussion
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  hadleFavorites() {
    if (this.discussionInfo.favouries) {
      this.groupService.deleteGroupFollow(this.discussionInfo.groupFollowId).subscribe(() => {
        this.discussionInfo.favouries = false;
      });
    } else {
      const groupFollow: IGroupFollow = new GroupFollow();
      groupFollow.userId = this.userId;
      groupFollow.groupId = this.discussionInfo.groupId;
      this.groupService.createGroupFollow(groupFollow).subscribe((res: IGroupFollow) => {
        this.discussionInfo.favouries = true;
        this.discussionInfo.groupFollowId = res.id;
      });
    }
  }

  updateGroup() {
    this.groupModelService.createGroup(this.currentGroup, this.handleUpdateGroup);
  }

  handleUpdateGroup = (result: any) => {
    this.currentGroup = result;
    this.currentGroup.isPublic = true;
    this.currentGroup.ownerId = this.currentAccount.id;
    this.groupService.updateGroup(this.currentGroup).subscribe(
      res => {
        this.countMyGroup = 0;
        this.getMyGroup(true);
      },
      (res: HttpErrorResponse) => {
        this.onError(res.error.title);
        // eslint-disable-next-line no-console
        console.log(res);
      }
    );
  };
}
