import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { AccountService, Account } from 'app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'gp-register',
  templateUrl: './register-modal.component.html',
  styles: []
})
export class RegisterModalComponent implements OnInit, AfterViewInit, OnDestroy {
  account: Account;
  constructor(private accountService: AccountService, public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  ngAfterViewInit() {
    const body = document.getElementsByTagName('body')[0];
    this.accountService.identity().then((account: Account) => {
      if (!account) {
        body.classList.add('landing-page');
      } else {
        body.classList.remove('landing-page');
      }
    });
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('landing-page');
  }

  cancel() {
    this.activeModal.dismiss('cancel');
  }
}
