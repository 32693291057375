import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/compiler/src/core';
import { CreateGroupComponent } from './create-group/create-group.component';
import { ReportPostModalComponent } from './report-post-modal/report-post-modal.component';
import { User } from 'app/core';

@Injectable({
  providedIn: 'root'
})
export class GroupsModalService {
  private isOpen = false;
  protected currentModal: NgbModalRef;
  constructor(private modalService: NgbModal) { }

  reportPost(userSend: number, userReceive: User, callBack: Function) {
    this.currentModal = this.open(ReportPostModalComponent as Component, 'lg', callBack);
    this.currentModal.componentInstance.userReceive = userReceive;
    this.currentModal.componentInstance.userSend = userSend;
  }

  createGroup(inputModel: any, callBack: Function) {
    this.currentModal = this.open(CreateGroupComponent as Component, 'lg', callBack);
    this.currentModal.componentInstance.inputModel = inputModel;
  }

  open(component: Component, sizeModal: NgbModalOptions['size'] = 'sm', callBack: Function): NgbModalRef {
    if (this.isOpen) {
      return;
    }
    this.isOpen = true;
    const modalRef = this.modalService.open(component, { size: sizeModal, backdrop: 'static' });
    modalRef.result.then(
      result => {
        this.isOpen = false;
        callBack(result);
        this.currentModal = null;
      },
      reason => {
        this.isOpen = false;
        this.currentModal = null;
      }
    );
    return modalRef;
  }
}
