import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from 'app/app.constants';
import { IUserProfile } from 'app/shared/model/user-profile.model';
import { IMedicalPractice } from 'app/shared/model/medical-practice.model';
import { createRequestOption } from 'app/shared';
import { ISpeciality } from 'app/shared/model/speciality.model';
import { IDiscussionGroup } from 'app/shared/model/discussion-group.model';
import { IEndorsementUser } from 'app/shared/model/endorsement-user.model';
import { IFavourities } from 'app/shared/model/favourities.model';
import { IUser } from 'app/shared/model/user.model';

type EntityResponseType = HttpResponse<IUserProfile>;
type MedicalPracticeEntityResponseType = HttpResponse<IMedicalPractice>;
type MedicalPracticesEntityArrayResponseType = HttpResponse<IMedicalPractice[]>;
type SpecialitiesEntityArrayResponseType = HttpResponse<ISpeciality[]>;
type PostGroupEntityArrayResponseType = HttpResponse<IDiscussionGroup[]>;
type EndorsementEntityArrayResponseType = HttpResponse<IEndorsementUser[]>;
type FavoritiesEntityResponseType = HttpResponse<IFavourities>;
type UserResponseType = HttpResponse<IUser>;

@Injectable({ providedIn: 'root' })
export class ProfileService {
  public resourceUrl = SERVER_API_URL + 'api/front/profiles';
  public medicalPracticeUrl = SERVER_API_URL + 'api/front/medical-practices';
  public specialityUrl = SERVER_API_URL + 'api/front/specialities';
  public publicGroupUrl = SERVER_API_URL + 'api/discussion-groups';
  public activityUrl = SERVER_API_URL + 'api/discussions';
  public endorsementUrl = SERVER_API_URL + 'api/endorsements';
  public favouritiesUrl = SERVER_API_URL + 'api/favourites';
  public profileUrl = SERVER_API_URL + 'api/front/profiles';

  constructor(protected http: HttpClient) {}

  update(userExtra: IUserProfile): Observable<EntityResponseType> {
    return this.http.put<IUserProfile>(this.resourceUrl, userExtra, { observe: 'response' });
  }

  patch(userId: number, userExtra: IUserProfile): Observable<EntityResponseType> {
    return this.http.patch<IUserProfile>(`${this.resourceUrl}/${userId}`, userExtra, { observe: 'response' });
  }

  findByUser(userId: number): Observable<EntityResponseType> {
    return this.http.get<IUserProfile>(`${this.resourceUrl}/${userId}`, { observe: 'response' });
  }

  getMedicalPractices(req?: any): Observable<MedicalPracticesEntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IMedicalPractice[]>(this.medicalPracticeUrl, { params: options, observe: 'response' });
  }

  createMedicalPractice(medicalPractice: IMedicalPractice): Observable<MedicalPracticeEntityResponseType> {
    return this.http.post<IMedicalPractice>(this.medicalPracticeUrl, medicalPractice, { observe: 'response' });
  }

  updateMedicalPractice(medicalPractice: IMedicalPractice): Observable<MedicalPracticeEntityResponseType> {
    return this.http.put<IMedicalPractice>(this.medicalPracticeUrl, medicalPractice, { observe: 'response' });
  }

  deleteMedicalPractice(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.medicalPracticeUrl}/${id}`, { observe: 'response' });
  }

  deleteSpeciality(id: number, req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.delete(`${this.specialityUrl}/${id}/user`, { params: options, observe: 'response' });
  }

  getSpecialities(req?: any): Observable<SpecialitiesEntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ISpeciality[]>(`${this.specialityUrl}`, { params: options, observe: 'response' });
  }

  findAllPrimary(req?: any): Observable<SpecialitiesEntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ISpeciality[]>(`${this.specialityUrl}/primaryOrSub`, { params: options, observe: 'response' });
  }

  getDiscussionGroupByFollow(req?: any): Observable<PostGroupEntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IDiscussionGroup[]>(`${this.publicGroupUrl}/follows`, { params: options, observe: 'response' });
  }

  createSpecialities(specialities: ISpeciality): Observable<any> {
    return this.http.post<ISpeciality>(this.specialityUrl, specialities, { observe: 'response' });
  }

  findActivity(req?: any): Observable<PostGroupEntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IDiscussionGroup[]>(`${this.publicGroupUrl}/activity`, { params: options, observe: 'response' });
  }

  findEndorsement(req?: any): Observable<EndorsementEntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IEndorsementUser[]>(`${this.endorsementUrl}/profile/`, { params: options, observe: 'response' });
  }

  createFavorities(favourities: IFavourities): Observable<any> {
    return this.http.post(this.favouritiesUrl, favourities);
  }

  deleteFavorities(favouritesId: number): Observable<any> {
    return this.http.delete(`${this.favouritiesUrl}/${favouritesId}`);
  }

  getFavorities(req?: any): Observable<FavoritiesEntityResponseType> {
    const option = createRequestOption(req);
    return this.http.get<IFavourities>(`${this.favouritiesUrl}/liked`, { params: option, observe: 'response' });
  }

  findAllSpecialitySub(): Observable<any> {
    return this.http.get<String[]>(`${this.specialityUrl}/subs`);
  }

  updateProfileView(userId: number): Observable<any> {
    return this.http.get(`${this.resourceUrl}/view/${userId}`);
  }

  findUser(userId: number): Observable<UserResponseType> {
    return this.http.get<IUser>(`${this.resourceUrl}/${userId}`, { observe: 'response' });
  }
  
  findByShortname(shortname: string): Observable<any> {
    return this.http.get(`${this.resourceUrl}/shortname/${shortname}`, { observe: 'response' });
  }
  
  setShortname(userId: number, shortname: string): Observable<any> {
    const params = new HttpParams()
    .set('shortname', shortname);
    return this.http.post(`${this.resourceUrl}/shortname/${userId}`, params, { observe: 'response' });
  }
  
  public suspendUser(userId: number): Observable<any> {
    return this.http.get(`${this.profileUrl}/${userId}/suspend`);
  }
}
