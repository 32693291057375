import { Moment } from 'moment';
import { IUser } from './user.model';

export interface IMedicalPractice {
  id?: number;
  name?: string;
  usualAvailability?: number;
  acceptUrgent?: boolean;
  monFrom?: string;
  monTo?: string;
  tueFrom?: string;
  tueTo?: string;
  wedFrom?: string;
  wedTo?: string;
  thuFrom?: string;
  thuTo?: string;
  friFrom?: string;
  friTo?: string;
  satFrom?: string;
  satTo?: string;
  sunFrom?: string;
  sunTo?: string;
  createdBy?: string;
  createdDate?: Moment;
  lastModifiedBy?: string;
  lastModifiedDate?: Moment;
  userLogin?: string;
  userId?: number;
  line?: string;
  unit?: number;
  number?: number;
  locality?: string;
  street?: string;
  postCode?: string;
  visible?: boolean;
  sortOrder?: number;
  lat?: number;
  lng?: number;
  state?: string;
  city?: string;
  country?: string;
  fullAddress?: string;
  user?: IUser;
}

export class MedicalPractice implements IMedicalPractice {
  constructor(
    public id?: number,
    public name?: string,
    public usualAvailability?: number,
    public acceptUrgent?: boolean,
    public monFrom?: string,
    public monTo?: string,
    public tueFrom?: string,
    public tueTo?: string,
    public wedFrom?: string,
    public wedTo?: string,
    public thuFrom?: string,
    public thuTo?: string,
    public friFrom?: string,
    public friTo?: string,
    public satFrom?: string,
    public satTo?: string,
    public sunFrom?: string,
    public sunTo?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public userLogin?: string,
    public userId?: number,
    public line?: string,
    public unit?: number,
    public number?: number,
    public locality?: string,
    public street?: string,
    public postCode?: string,
    public visible?: boolean,
    public sortOrder?: number,
    public lat?: number,
    public lng?: number,
    public state?: string,
    public city?: string,
    public country?: string,
    public fullAddress?: string
  ) {
    this.acceptUrgent = this.acceptUrgent || false;
  }
}
