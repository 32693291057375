import { Moment } from 'moment';
import { IDiscussionUser } from './discussion-user.model';

export interface IDiscussions {
  groupId?: number;
  groupName?: string;
  description?: string;
  favouries?: boolean;
  groupFollowId?: number;
  discussions?: IDiscussionUser[];
  createdDate?: Moment;
}

export class Discussions implements IDiscussions {
  constructor(
    public groupId?: number,
    public groupName?: string,
    public description?: string,
    public favouries?: boolean,
    public groupFollowId?: number,
    public discussions?: IDiscussionUser[],
    public createdDate?: Moment
  ) {
    this.groupId = groupId || null;
    this.groupName = groupName || null;
    this.description = description || null;
    this.favouries = favouries || false;
    this.groupFollowId = groupFollowId || null;
    this.discussions = discussions || [];
    this.createdDate = createdDate || null;
  }
}
